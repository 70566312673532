import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PRODUCT_RESOURCES_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const addProductResource = createAsyncThunk(`${PRODUCT_RESOURCES_REDUCER_NAME}/addProductResource`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth, analytics } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('invalid-user');
        }
        const model = {
            ...payload.model,
            createdAt: new Date(),
            updatedAt: new Date(),
            notifications: [],
            id: uuid(),
        };
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_RESOURCES)
            .doc(model.id);
        await ref.set(model);
        await analytics.track('add_resource_to_product', {
            productId: payload.productId,
            userId: user.uid,
        });
        onSuccess?.();
        return model.id;
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
