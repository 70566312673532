import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { LessonType, } from '../../../../models/documents/ProductContentDocument';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
import { getReadingTime } from '../../../../helpers/getReadingTime';
import dayjs from 'dayjs';
import cloneDeep from 'lodash.clonedeep';
export const updateContentLesson = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/updateContentLesson`, async ({ productId, moduleId, lessonDto, lesson }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    if (!data || !data.modules.some((module) => module.id === moduleId)) {
        throw new Error('invalid-module');
    }
    const now = dayjs();
    const batch = db.batch();
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
    const lessonOnList = data.modules
        .find((module) => module.id === moduleId)
        ?.lessons.find((lesson) => lesson.id === lessonDto.id);
    if (lessonOnList) {
        const updated = {
            ...lessonOnList,
            title: lessonDto.title,
            description: lessonDto.description,
            tags: lessonDto.tags,
            status: lessonDto.status,
            type: lessonDto.type,
            moduleId: lessonDto.moduleId,
            thumbnail: lessonDto.thumbnail || null,
            updatedAt: now.unix(),
            ...(lesson.type === LessonType.Video
                ? { size: lesson.video?.originalSize || null, length: lesson.video?.length || null }
                : {}),
            ...(lesson.type === LessonType.ExternalVideo
                ? {
                    length: lessonDto.video?.length || null,
                    video: lessonDto.video,
                }
                : {}),
            ...(lesson.type === LessonType.Article
                ? { length: getReadingTime(lessonDto.content) || null }
                : {}),
        };
        let updatedModules = cloneDeep(data.modules);
        if (lesson.moduleId !== lessonDto.moduleId) {
            updatedModules = updatedModules.map((module) => module.id === lesson.moduleId
                ? { ...module, lessons: module.lessons.filter((lesson) => lesson.id !== lessonDto.id) }
                : module);
            const moduleIndex = updatedModules.findIndex((module) => module.id === lessonDto.moduleId);
            updatedModules[moduleIndex].lessons.push(updated);
        }
        else {
            updatedModules = updatedModules.map((module) => module.id === moduleId
                ? {
                    ...module,
                    lessons: module.lessons.map((lesson) => lesson.id === lessonDto.id ? updated : lesson),
                }
                : module);
        }
        batch.update(ref, {
            modules: updatedModules,
        });
    }
    batch.update(ref.collection(COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonDto.id), {
        ...lessonDto,
        updatedAt: now.toDate(),
    });
    await batch.commit();
    analytics.track('update_content_lesson', {
        ownerId: user.uid,
        productId,
        moduleId,
        type: lesson.type,
    });
    return lesson.id;
});
