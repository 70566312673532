import { createAsyncThunk } from '@reduxjs/toolkit';
import { SUBSCRIPTION_REDUCER_NAME } from '../types';
export const checkSubscriptionInvoice = createAsyncThunk(`${SUBSCRIPTION_REDUCER_NAME}/checkSubscriptionInvoice`, async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
        const res = await functions.httpsCallable('subscriptions-checkSubscriptionInvoice')(payload);
        return res.data?.body;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
