import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchNextPageProductClients = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchNextPageProductClients`, async ({ productId, page, ownerId }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const { productClientsListSize, isLastPage: isFetchedAllItems, productClientsPage, productClients, } = getState().product;
        if (isFetchedAllItems || (productClientsPage && page <= productClientsPage)) {
            return {
                page,
                list: [],
                isLastPage: isFetchedAllItems,
            };
        }
        const countPages = page - Math.ceil(productClients.length / productClientsListSize);
        if (countPages <= 0) {
            return {
                page,
                list: [],
                isLastPage: isFetchedAllItems,
            };
        }
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .where('status', '==', 'active')
            .orderBy('createdAt', 'desc')
            .limit(productClientsListSize * (page - productClientsPage))
            .startAfter(firestoreSubscriptions.lastProductClientCursor);
        const snapshot = await ref.get();
        const isLastPage = snapshot.docs.length < productClientsListSize;
        firestoreSubscriptions.lastProductClientCursor = isLastPage
            ? null
            : snapshot.docs[snapshot.docs.length - 1];
        const list = snapshot.docs.map((doc) => firestoreDateMapper(doc));
        const currentPage = productClientsPage || 1;
        return {
            list,
            isLastPage,
            page: page - currentPage,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
