import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../..';
export const findFreeAccessAccountByEmail = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/findFreeAccessAccountByEmail`, async ({ productId, email }, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS)
            .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_FREE_ACCESS)
            .where('email', '==', email);
        const doc = await ref.get();
        return !doc.empty;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
