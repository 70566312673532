import { sendLoginLinkSuccess, sendLoginLinkStarted } from '../reducer';
export const sendLoginLink = (email, continueUrl, lang) => async (dispatch, _, { functions }) => {
    dispatch(sendLoginLinkStarted());
    try {
        await functions.httpsCallable('users-sendLinkToLogin')({ email, continueUrl, lang });
    }
    catch (e) {
        // show success message even for wrong data
    }
    finally {
        dispatch(sendLoginLinkSuccess());
    }
};
