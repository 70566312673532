export var UserStatus;
(function (UserStatus) {
    UserStatus["CREATING_NEW_ACCOUNT"] = "CREATING_NEW_ACCOUNT";
    UserStatus["LOGGING_IN"] = "LOGGING_IN";
    UserStatus["IS_LOGGED"] = "IS_LOGGED";
    UserStatus["IS_LOGGED_OUT"] = "IS_LOGGED_OUT";
    UserStatus["LOGGING_OUT"] = "LOGGING_OUT";
    UserStatus["HAS_ERROR"] = "HAS_ERROR";
    UserStatus["NEW_ACCOUNT_CREATED"] = "NEW_ACCOUNT_CREATED";
})(UserStatus || (UserStatus = {}));
