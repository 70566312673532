import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const removeContentLesson = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/removeContentLesson`, async ({ productId, moduleId, lessonId }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    if (!data || !data.modules.some((module) => module.id === moduleId)) {
        throw new Error('invalid-module');
    }
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
    const batch = db.batch();
    batch.delete(ref.collection(COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId));
    batch.update(ref, {
        modules: data.modules.map((module) => module.id === moduleId
            ? { ...module, lessons: module.lessons.filter((lesson) => lesson.id !== lessonId) }
            : module),
    });
    await batch.commit();
    analytics.track('remove_content_lesson', {
        ownerId: user.uid,
        productId,
        moduleId,
    });
});
