import { createAsyncThunk } from '@reduxjs/toolkit';
import { STATISTICS_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import dayjs from 'dayjs';
export const fetchProductMonthStats = createAsyncThunk(`${STATISTICS_REDUCER_NAME}/fetchProductMonthStats`, async ({ productId, date, ownerId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const monthStr = (date || dayjs()).format('YYYY-M');
        const ref = db
            .collection(COLLECTION.REPORTS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.STATS_PER_PRODUCT)
            .doc(productId)
            .collection(COLLECTION.STATS_PER_PRODUCT_MONTH)
            .doc(monthStr);
        const doc = await ref.get();
        return doc.exists
            ? firestoreDateMapper(doc)
            : null;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
