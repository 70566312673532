import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchNextPageProductClientImports = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchNextPageProductClientImports`, async ({ productId, page, ownerId, importId }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const { productClientImportsListSize, productClientImportsPage, productClientImports, isLastProductClientImportsPage, } = getState().product;
        if (isLastProductClientImportsPage ||
            (productClientImportsPage && page <= productClientImportsPage)) {
            return {
                page,
                list: [],
                isLastPage: true,
            };
        }
        const countPages = page - Math.ceil(productClientImports.length / productClientImportsListSize);
        if (countPages <= 0) {
            return {
                page,
                list: [],
                isLastPage: true,
            };
        }
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_IMPORTS)
            .where('importId', '==', importId)
            .orderBy('createdAt', 'desc')
            .limit(productClientImportsListSize * (page - productClientImportsPage))
            .startAfter(firestoreSubscriptions.lastProductClientImportsCursor);
        const snapshot = await ref.get();
        const isLastPage = snapshot.docs.length < productClientImportsPage;
        firestoreSubscriptions.lastProductClientImportsCursor = isLastPage
            ? null
            : snapshot.docs[snapshot.docs.length - 1];
        const list = snapshot.docs.map((doc) => firestoreDateMapper(doc));
        const currentPage = productClientImportsPage || 1;
        return {
            list,
            isLastPage,
            page: page - currentPage,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
