import React, { FunctionComponent, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useQuery } from '~/hooks/useQuery';
import { AppStore, productActions, RequestStatus } from '@mailingr/shared';
import { useAppDispatch } from '~/initializeStore';
import { Redirect } from 'react-router-dom';
import FullPageLoader from '~/components/FullPageLoader/FullPageLoader';
import FullPageAlert from '~/components/FullPageAlert/FullPageAlert';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PaywallError: FunctionComponent<{ errorCode: string }> = ({ errorCode }) => {
  const { t } = useTranslation('products');

  return (
    <FullPageAlert
      type="info"
      message={
        <div>
          <h1 className="text-2xl">{t<string>('paywall.error.title')}</h1>
          <p>{t<string>('paywall.error.description')}</p>

          <details>
            <summary className="font-medium leading-6">
              {t<string>('paywall.error.errorDetails')}
            </summary>
            <div className="mt-3 leading-6 pl-3.5 pr-3.5">
              <span className="font-medium underline underline-offset-2 decoration-neutral-400 decoration-dashed">
                {t<string>('paywall.error.errorCode')}
              </span>
              <pre className="whitespace-pre-wrap">{errorCode}</pre>
              <span className="font-medium underline underline-offset-2 decoration-neutral-400 decoration-dashed">
                {t<string>('paywall.error.url')}
              </span>
              <pre className="whitespace-pre-wrap">{window.location.href}</pre>
            </div>
          </details>
        </div>
      }
    />
  );
};

export const ProductPaywall: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { params } = useRouteMatch<{ productId: string }>();
  const query = useQuery();
  const continueUrl = query.get('continueUrl') || window.location.origin;
  const loggedIn = query.get('loggedIn') === 'true';
  const { checkAccessInfo, checkAccessInfoStatus } = useSelector(
    (store: AppStore) => store.product
  );

  if (!params.productId) {
    return <PaywallError errorCode="missing-product-id" />;
  }

  useEffect(() => {
    if (!params.productId) {
      return;
    }

    dispatch(productActions.checkAccessToProduct({ payload: { productId: params.productId } }));
  }, [dispatch, params.productId, continueUrl]);

  if (checkAccessInfoStatus === RequestStatus.FAILED) {
    return <PaywallError errorCode="check-access-failed" />;
  }

  if (!checkAccessInfoStatus || checkAccessInfoStatus === RequestStatus.FETCHING) {
    return <FullPageLoader />;
  }

  if (!checkAccessInfo) {
    return <PaywallError errorCode="missing-check-access-info" />;
  }

  if (!checkAccessInfo.hasAccess) {
    if (checkAccessInfo.reason === 'product-not-found') {
      return <PaywallError errorCode="product-not-found" />;
    }

    if (checkAccessInfo.reason === 'not-logged-in') {
      if (loggedIn) {
        // workaround for delay between firebase auth state change and check access info reload
        return <FullPageLoader />;
      }

      const loginParams = new URLSearchParams();
      loginParams.set(
        'continue',
        window.location.pathname + window.location.search + '&loggedIn=true'
      );
      loginParams.set('paywallProductId', params.productId);

      return <Redirect to={`/auth/login?${loginParams.toString()}`} />;
    }

    if (checkAccessInfo.reason === 'not-active-owner') {
      const checkoutUrl = new URL(checkAccessInfo.product.checkoutUrl);

      window.location.replace(checkoutUrl);

      return <FullPageLoader />;
    }
  }

  if (checkAccessInfo.hasAccess) {
    const url = new URL(continueUrl);
    url.searchParams.set('signature', checkAccessInfo.product.signature);

    window.location.replace(url);
    return <FullPageLoader />;
  }

  return <PaywallError errorCode="unknown" />;
};
