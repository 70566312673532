import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const updateProductReferrals = createAsyncThunk(`${PRODUCT_REFERRALS_REDUCER_NAME}/updateProductReferrals`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('invalid-user');
        }
        const { data } = getState().productReferrals;
        if (!data) {
            throw new Error('product referrals data not exists');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_CONFIG)
            .doc(FIRESTORE_DOCUMENT.PRODUCT_REFERRALS);
        await ref.update({
            ...payload,
            updatedAt: new Date(),
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue();
    }
});
