import { createSelector } from '@reduxjs/toolkit';
const getProducts = (store) => store.products.productsListData;
const getSearchTerm = (store) => store.products.searchTerm;
export const getFilteredProducts = createSelector([getProducts, getSearchTerm], (products, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
        return products;
    }
    const searchInLowerCase = searchTerm.toLowerCase();
    return products.filter((product) => {
        return product.name.toLowerCase().includes(searchInLowerCase);
    });
});
export const getFilteredPaidProducts = createSelector([getProducts], (products) => {
    return products.filter((product) => {
        return !product.isFree;
    });
});
