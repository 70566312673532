import React from 'react';
import FrameAuthChecker from '~/components/AuthChecker/FrameAuthChecker';
import { Route, Switch } from 'react-router-dom';
import FrameAuth from '~/pages/FrameAuth/Auth';

const FrameRoutes = () => {
  return (
    <FrameAuthChecker>
      <Switch>
        <Route path="/frame/auth" component={FrameAuth} />
      </Switch>
    </FrameAuthChecker>
  );
};

export default FrameRoutes;
