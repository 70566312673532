import { createSlice } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    error: null,
    status: null,
    data: null,
    showProductList: false,
};
const integrationSlice = createSlice({
    name: INTEGRATION_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToIntegrationStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
            state.error = null;
        },
        subscribeToIntegrationSuccess(state, { payload }) {
            state.status = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToIntegrationFailed(state, { payload }) {
            state.status = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromIntegration(state) {
            state.status = null;
            state.error = null;
            state.data = null;
        },
    },
});
export const { subscribeToIntegrationFailed, subscribeToIntegrationStarted, subscribeToIntegrationSuccess, unsubscribeFromIntegration, } = integrationSlice.actions;
export default integrationSlice.reducer;
