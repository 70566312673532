import { UserActivityStatus } from '../../../../enums/userActivityStatus';
export const setUserStatus = async (database, userId) => {
    const userStatusDatabaseRef = database().ref(`/status/${userId}`);
    const isOfflineForDatabase = {
        state: UserActivityStatus.Offline,
        lastChanged: database.ServerValue.TIMESTAMP,
    };
    const isOnlineForDatabase = {
        state: UserActivityStatus.Online,
        lastChanged: database.ServerValue.TIMESTAMP,
    };
    return database()
        .ref('.info/connected')
        .on('value', function (snapshot) {
        if (snapshot.val() == false) {
            return;
        }
        userStatusDatabaseRef
            .onDisconnect()
            .set(isOfflineForDatabase)
            .then(function () {
            userStatusDatabaseRef.set(isOnlineForDatabase);
        })
            .catch((e) => {
            // eslint-disable-next-line no-console
            console.log('Error with user status', e);
        });
    });
};
