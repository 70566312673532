import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { updateUserData } from './updateUserData';
export const createReferralCode = createAsyncThunk(`${USER_REDUCER}/createReferralCode`, async (_, { dispatch, rejectWithValue, extra: { firestore, auth } }) => {
    try {
        const user = await auth().currentUser;
        if (!user || !user.email) {
            throw new Error('User is not logged');
        }
        const res = await firestore().collection(COLLECTION.REFERRAL_CODES).add({
            createdAt: firestore.FieldValue.serverTimestamp(),
            updatedAt: firestore.FieldValue.serverTimestamp(),
            email: user.email.toLowerCase(),
        });
        dispatch(updateUserData({
            uid: user.uid,
            refCode: res.id,
        }));
    }
    catch (e) {
        if (e instanceof Error) {
            throw rejectWithValue(e.message);
        }
        throw rejectWithValue('Unknown error');
    }
});
