import { createAsyncThunk } from '@reduxjs/toolkit';
import { ADMIN_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const fetchProducts = createAsyncThunk(`${ADMIN_REDUCER_NAME}/fetchProducts`, async (payload, { extra: { db } }) => {
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(payload)
        .collection(COLLECTION.PRODUCTS_LIST)
        .where('archived', '==', false)
        .orderBy('created', 'desc')
        .withConverter(getDocumentConverter());
    const snapshot = await ref.get();
    return snapshot.docs.map((doc) => doc.data());
});
