import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { LessonStatus, LessonType, } from '../../../../models/documents/ProductContentDocument';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
import dayjs from 'dayjs';
export const addContentLesson = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/addContentLesson`, async ({ productId, moduleId, lessonDto: { type, ...lessonBase } }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    if (!data || !data.modules.some((module) => module.id === moduleId)) {
        throw new Error('invalid-module');
    }
    const now = dayjs();
    const lessonId = uuid();
    const lesson = {
        ...lessonBase,
        id: lessonId,
        productId,
        ownerId: user.uid,
        content: '',
        editorContent: '',
        blockEditor: false,
        status: LessonStatus.Draft,
        moduleId,
        createdAt: now.toDate(),
        updatedAt: now.toDate(),
        thumbnail: null,
        author: null,
        tags: [],
        attachments: [],
        ...(type === LessonType.Video || type === LessonType.ExternalVideo
            ? { video: null, type }
            : { type }),
    };
    const lessonItemList = {
        ...lessonBase,
        type,
        status: LessonStatus.Draft,
        thumbnail: null,
        tags: [],
        id: lessonId,
        moduleId,
        createdAt: now.unix(),
        updatedAt: now.unix(),
        length: null,
        size: null,
    };
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
    const batch = db.batch();
    batch.set(ref.collection(COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId), lesson);
    batch.update(ref, {
        modules: data.modules.map((module) => module.id === moduleId
            ? { ...module, lessons: [...module.lessons, lessonItemList] }
            : module),
    });
    await batch.commit();
    analytics.track('add_content_lesson', {
        ownerId: user.uid,
        productId,
        moduleId,
        type,
    });
    return lesson.id;
});
