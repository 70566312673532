import { createAsyncThunk } from '@reduxjs/toolkit';
import { ARCHIVE_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import keyBy from 'lodash.keyby';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const copyEmailsIntoProducts = createAsyncThunk(`${ARCHIVE_REDUCER_NAME}/copyEmailsIntoProducts`, async ({ emails, productsIds }, { getState, extra: { db, analytics } }) => {
    const ref = db.collection(COLLECTION.PRODUCTS_ARCHIVE);
    const batch = db.batch();
    const productsByKey = keyBy(getState().products.productsListData, 'id');
    productsIds.forEach((productId) => {
        emails.forEach((email) => {
            const newRef = ref
                .doc(productId)
                .collection(COLLECTION.PRODUCT_ARCHIVE_EMAILS)
                .withConverter(getDocumentConverter())
                .doc();
            batch.set(newRef, {
                ...email,
                productId,
                productSlug: productsByKey[productId].slug || null,
                productName: productsByKey[productId].name ?? '',
            });
        });
    });
    await batch.commit();
    analytics.track('copy_emails_into_another_products', {
        email: emails.length,
        products: productsIds.length,
    });
});
