import { createSlice } from '@reduxjs/toolkit';
import { ORDERS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../..';
const initialState = {
    orders: [],
    ordersStatus: null,
    productId: null,
    searchTerm: null,
    startMonth: null,
    endMonth: null,
    error: null,
};
const ordersSlice = createSlice({
    name: ORDERS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToOrdersStarted(state, { payload }) {
            state.ordersStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
            state.startMonth = payload.startMonth;
            state.endMonth = payload.endMonth;
        },
        subscribeToOrdersSuccess(state, { payload }) {
            state.ordersStatus = RequestStatus.SUBSCRIBED;
            state.orders = payload;
        },
        subscribeToOrdersFailed(state, { payload }) {
            state.ordersStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromOrders() {
            return initialState;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
});
export const { subscribeToOrdersFailed, subscribeToOrdersStarted, subscribeToOrdersSuccess, unsubscribeFromOrders, clearSearchMode, setSearchTerm, } = ordersSlice.actions;
export default ordersSlice.reducer;
