import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
import cloneDeep from 'lodash.clonedeep';
export const updateProductAutomation = createAsyncThunk(`${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomation`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth, functions }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('invalid-user');
        }
        const { data } = getState().productAutomation;
        if (!data) {
            throw new Error('product automation data not exists');
        }
        let webhooksToUpdate = cloneDeep(data.webhooks);
        if (payload.action === 'remove') {
            webhooksToUpdate = webhooksToUpdate.filter((webhook) => webhook.id !== payload.webhook.id);
        }
        if (payload.action === 'update') {
            webhooksToUpdate = data.webhooks.map((savedWebhook) => savedWebhook.id === payload.webhook.id ? payload.webhook : savedWebhook);
        }
        if (payload.action === 'add') {
            webhooksToUpdate = [...(data?.webhooks || []), payload.webhook];
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_CONFIG)
            .doc(FIRESTORE_DOCUMENT.PRODUCT_AUTOMATION);
        if (payload.action === 'remove' && payload.webhook.auth) {
            await functions.httpsCallable('secrets-removeSecret')({
                productId: payload.productId,
                connectedResource: 'webhook',
                resourceId: payload.webhook.id,
            });
        }
        await ref.update({
            webhooks: webhooksToUpdate,
            updatedAt: new Date(),
        });
        if ((payload.action === 'add' || payload.action === 'update') && payload.authToProcess) {
            await functions.httpsCallable('secrets-upsertSecret')({
                productId: payload.productId,
                connectedResource: 'webhook',
                resourceId: payload.webhook.id,
                auth: payload.authToProcess,
            });
        }
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
