import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTER_REDUCER_NAME } from '../types';
export const resumeNewsletterSubscriptionAtCurrentPeriodEnd = createAsyncThunk(`${NEWSLETTER_REDUCER_NAME}/resumeNewsletterSubscriptionAtCurrentPeriodEnd`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions } }) => {
    try {
        const res = await functions.httpsCallable('newsletters-resumeNewsletterAtEndCurrentSubscription')(payload);
        onSuccess?.(res.data?.body);
        return res.data;
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
