import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../../product/types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { NotificationStatus } from '../../../../models/documents/NotificationDocument';
export const markAsRead = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/markAsRead`, async (notificationId, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.NOTIFICATIONS)
            .doc(notificationId);
        await ref.update({
            status: NotificationStatus.READ,
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
