import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const changeProductClientEmail = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/changeProductClientEmail`, async (payload, { rejectWithValue, extra: { functions, analytics, auth, firestoreSubscriptions } }) => {
    try {
        const user = auth().currentUser;
        firestoreSubscriptions.newsletterListener?.();
        await functions.httpsCallable('products-changeProductClientEmail')(payload);
        analytics.track('change_product_client_email', {
            productId: payload.productId,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
