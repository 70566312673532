import { signUpFailed, signUpStarted, signUpSuccess } from '../reducer';
import { logInUser } from './logInUser';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { UserRole } from '../../../../enums/userRole';
import { addCreatorToMailingList } from './addCreatorToMailingList';
async function getCountry(functions) {
    try {
        const result = await functions.httpsCallable('users-getUserMetadata')();
        return result.data.body.country;
    }
    catch (e) {
        return null;
    }
}
export const signUpUser = ({ email, password, lastName, firstName, termsAndPrivacyPolicy, lang, timezone, ip, ref, phoneNumber, }, callback, onFail) => async (dispatch, _, { auth, functions, firestore }) => {
    dispatch(signUpStarted());
    try {
        const res = await auth().createUserWithEmailAndPassword(email, password);
        if (!res.user) {
            throw new Error('Error when creating new account');
        }
        const country = await getCountry(functions);
        await firestore()
            .collection(COLLECTION.USERS)
            .doc(res.user.uid)
            .set({
            firstName,
            lastName,
            email: email.toLowerCase(),
            ip,
            uid: res.user.uid,
            createdAt: firestore.FieldValue.serverTimestamp(),
            updatedAt: firestore.FieldValue.serverTimestamp(),
            selectedRole: UserRole.Creator,
            allowedRoles: [UserRole.Creator, UserRole.Subscriber],
            termsAndPolicyAcceptDate: firestore.FieldValue.serverTimestamp(),
            termsAndPrivacyPolicy,
            lang,
            timezone,
            ...(country && { country }),
            phoneNumber,
        });
        await dispatch(addCreatorToMailingList({
            firstName,
            lastName,
            email,
            country: country ?? 'PL',
        }));
        dispatch(signUpSuccess());
        dispatch(logInUser({ email, password }));
        if (callback) {
            callback();
        }
        if (ref) {
            await firestore()
                .collection(COLLECTION.REFERRAL_CODES)
                .doc(ref)
                .collection(COLLECTION.REFERRAL_REGISTRATIONS)
                .add({
                createdAt: firestore.FieldValue.serverTimestamp(),
                updatedAt: firestore.FieldValue.serverTimestamp(),
                email: email.toLowerCase(),
                from: ref,
            });
        }
    }
    catch (e) {
        dispatch(signUpFailed(e.code));
        if (onFail) {
            onFail();
        }
    }
};
