export var ArchivedEmailStatus;
(function (ArchivedEmailStatus) {
    ArchivedEmailStatus["Public"] = "public";
    ArchivedEmailStatus["Hidden"] = "hidden";
})(ArchivedEmailStatus || (ArchivedEmailStatus = {}));
export var ArchivedEmailVisibility;
(function (ArchivedEmailVisibility) {
    ArchivedEmailVisibility["Public"] = "public";
    ArchivedEmailVisibility["Hidden"] = "hidden";
    ArchivedEmailVisibility["Locked"] = "locked";
})(ArchivedEmailVisibility || (ArchivedEmailVisibility = {}));
