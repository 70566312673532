import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash.uniqby';
import { PRODUCT_REDUCER_NAME } from './types';
import { RequestStatus } from '../../..';
import { updateProduct } from './actions/updateProduct';
import { fetchNextPageProductClients } from './actions/fetchNextPageProductClients';
import { fetchProductClients } from './actions/fetchProductClients';
import { fetchArchivedProductClients } from './actions/fetchArchivedProductClients';
import { fetchFreeAccessInvitation } from './actions/fetchFreeAccessInvitation';
import { fetchNextPageFreeAccessInvitation } from './actions/fetchNextPageFreeAccessInvitation';
import { addFreeAccessInvitation } from './actions/addFreeAccessInvitation';
import { fetchProductOrderByEmail } from './actions/fetchProductOrderByEmail';
import { updateProductPrices } from './actions/updateProductPrices';
import { getCheckoutProductInfo } from './actions/getCheckoutProductInfo';
import { fetchNextProductOrdersByEmail } from './actions/fetchNextProductOrdersByEmail';
import { fetchNextPageProductArchivedClients } from './actions/fetchNextPageProductArchivedClients';
import { searchProductClient } from './actions/searchProductClient';
import { removeArchivedClient } from './actions/removeArchivedClient';
import { updateClientDiscordId } from '../newsletter/actions/updateClientDiscordId';
import { updateProductPricesOrder } from './actions/updateProductPricesOrder';
import { fetchProductCourseProgress } from './actions/fetchProductCourseProgress';
import { fetchNextPageProductCourseProgress } from './actions/fetchNextPageProductCourseProgress';
import { fetchProductClientImports } from './actions/fetchProductClientImports';
import { fetchNextPageProductClientImports } from './actions/fetchNextPageProductClientImports';
import { checkAccessToProduct } from './actions/checkAccessToProduct';
const initialState = {
    productDetailsStatus: null,
    details: null,
    productError: null,
    productCourseProgress: [],
    productCourseProgressStatus: null,
    productCourseProgressSize: 50,
    productCourseProgressPage: 0,
    isLastPageForProgress: false,
    productClients: [],
    productClientsListSize: 50,
    productOrdersListSize: 50,
    productClientsListStatus: null,
    productClientsPage: 0,
    productClientsActiveTotal: 0,
    productOrders: [],
    productOrdersPage: 0,
    isLastOrdersPage: false,
    productOrdersStatus: null,
    isLastPage: false,
    productId: null,
    freeAccessList: [],
    freeAccessPage: 0,
    freeAccessListSize: 100,
    freeAccessListStatus: null,
    freeAccessIsLastPage: false,
    addingNewInvitation: false,
    productPricesActions: null,
    checkoutProductInfo: null,
    checkoutProductInfoStatus: null,
    productOrdersClientEmail: null,
    productArchivedClients: [],
    productArchivedClientsListSize: 50,
    productArchivedListStatus: null,
    productArchivedPage: 0,
    isLastPageForArchived: false,
    searchStatus: null,
    searchTerm: '',
    productClientImportsListSize: 50,
    productClientImports: [],
    productClientImportsListStatus: null,
    productClientImportsPage: 0,
    isLastProductClientImportsPage: false,
    productClientTotalImports: 0,
    checkAccessInfo: null,
    checkAccessInfoStatus: null,
};
const productsSlice = createSlice({
    name: PRODUCT_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductStarted(state, { payload }) {
            state.productDetailsStatus = RequestStatus.SUBSCRIBING;
            state.productError = null;
            state.productId = payload || null;
        },
        subscribeToProductSuccess(state, { payload }) {
            state.productDetailsStatus = RequestStatus.SUBSCRIBED;
            state.details = payload;
        },
        subscribeToProductFailed(state, { payload }) {
            state.productDetailsStatus = RequestStatus.FAILED;
            state.productError = payload;
        },
        unsubscribeFromProductDetails() {
            return initialState;
        },
        clearSearchMode(state) {
            state.searchStatus = null;
            state.searchTerm = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductClients.pending, (state) => {
            state.productClientsListStatus = RequestStatus.FETCHING;
            state.productClients = [];
        });
        builder.addCase(fetchProductClients.fulfilled, (state, { payload }) => {
            state.productClientsListStatus = RequestStatus.SUCCESS;
            state.productClientsPage = 1;
            state.isLastPage = payload.isLastPage;
            state.productClients = payload.list;
            state.productClientsActiveTotal = payload.total;
        });
        builder.addCase(fetchProductClients.rejected, (state) => {
            state.productClientsListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchProductCourseProgress.pending, (state) => {
            state.productCourseProgressStatus = RequestStatus.FETCHING;
            state.productCourseProgress = [];
        });
        builder.addCase(fetchProductCourseProgress.fulfilled, (state, { payload }) => {
            state.productCourseProgressStatus = RequestStatus.SUCCESS;
            state.productCourseProgressPage = 1;
            state.isLastPageForProgress = payload.isLastPage;
            state.productCourseProgress = payload.list;
        });
        builder.addCase(fetchProductCourseProgress.rejected, (state) => {
            state.productCourseProgressStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchNextPageProductCourseProgress.pending, (state) => {
            state.productCourseProgressStatus = RequestStatus.UPDATING;
        });
        builder.addCase(fetchNextPageProductCourseProgress.fulfilled, (state, { payload }) => {
            state.productCourseProgressStatus = RequestStatus.SUCCESS;
            state.productCourseProgressPage = payload.list.length
                ? state.productCourseProgressPage + payload.page ?? 1
                : payload.page;
            state.isLastPageForProgress = payload.isLastPage;
            state.productCourseProgress = [...state.productCourseProgress, ...payload.list];
        });
        builder.addCase(fetchNextPageProductCourseProgress.rejected, (state) => {
            state.productCourseProgressStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchArchivedProductClients.pending, (state) => {
            state.productArchivedListStatus = RequestStatus.FETCHING;
            state.productArchivedClients = [];
        });
        builder.addCase(fetchArchivedProductClients.fulfilled, (state, { payload }) => {
            state.productArchivedListStatus = RequestStatus.SUCCESS;
            state.productArchivedPage = 1;
            state.isLastPageForArchived = payload.isLastPage;
            state.productArchivedClients = payload.list;
        });
        builder.addCase(fetchArchivedProductClients.rejected, (state) => {
            state.productArchivedListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchNextPageProductClients.pending, (state) => {
            state.productClientsListStatus = RequestStatus.UPDATING;
        });
        builder.addCase(fetchNextPageProductClients.fulfilled, (state, { payload }) => {
            state.productClientsListStatus = RequestStatus.SUCCESS;
            state.productClientsPage = payload.list.length
                ? state.productClientsPage + payload.page ?? 1
                : payload.page;
            state.isLastPage = payload.isLastPage;
            state.productClients = [...state.productClients, ...payload.list];
        });
        builder.addCase(fetchNextPageProductArchivedClients.rejected, (state) => {
            state.productArchivedListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchNextPageProductArchivedClients.pending, (state) => {
            state.productArchivedListStatus = RequestStatus.UPDATING;
        });
        builder.addCase(fetchNextPageProductArchivedClients.fulfilled, (state, { payload }) => {
            state.productArchivedListStatus = RequestStatus.SUCCESS;
            state.productArchivedPage = payload.list.length
                ? state.productArchivedPage + payload.page ?? 1
                : payload.page;
            state.isLastPageForArchived = payload.isLastPage;
            state.productArchivedClients = [...state.productArchivedClients, ...payload.list];
        });
        builder.addCase(fetchNextPageProductClients.rejected, (state) => {
            state.productClientsListStatus = RequestStatus.FAILED;
        });
        builder.addCase(updateProductPrices.pending, (state, { meta }) => {
            state.productPricesActions = meta.arg.payload.prices.map((payload) => ({
                action: payload.action,
                id: 'price' in payload ? payload.price.id ?? '' : payload.priceId,
            }));
        });
        builder.addCase(updateProductPrices.fulfilled, (state) => {
            state.productPricesActions = null;
        });
        builder.addCase(updateProductPrices.rejected, (state) => {
            state.productPricesActions = null;
        });
        builder.addCase(updateProduct.pending, (state, { meta }) => {
            state.productDetailsStatus = RequestStatus.UPDATING;
            state.productId = meta.arg.payload.id || null;
        });
        builder.addCase(updateProduct.fulfilled, (state) => {
            state.productDetailsStatus = RequestStatus.SUBSCRIBED;
        });
        builder.addCase(updateProduct.rejected, (state) => {
            state.productDetailsStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchFreeAccessInvitation.pending, (state) => {
            state.freeAccessListStatus = RequestStatus.FETCHING;
        });
        builder.addCase(fetchFreeAccessInvitation.fulfilled, (state, { payload }) => {
            state.freeAccessListStatus = RequestStatus.SUCCESS;
            state.freeAccessPage = 1;
            state.freeAccessIsLastPage = payload.isLastPage;
            state.freeAccessList = payload.list;
        });
        builder.addCase(fetchFreeAccessInvitation.rejected, (state) => {
            state.freeAccessListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchNextPageFreeAccessInvitation.pending, (state) => {
            state.freeAccessListStatus = RequestStatus.UPDATING;
        });
        builder.addCase(fetchNextPageFreeAccessInvitation.fulfilled, (state, { payload }) => {
            state.freeAccessListStatus = RequestStatus.SUCCESS;
            state.freeAccessPage = payload.list.length
                ? state.freeAccessPage + payload.page ?? 1
                : state.freeAccessPage;
            state.freeAccessIsLastPage = payload.isLastPage;
            state.freeAccessList = [...state.freeAccessList, ...payload.list];
        });
        builder.addCase(fetchNextPageFreeAccessInvitation.rejected, (state) => {
            state.freeAccessListStatus = RequestStatus.FAILED;
        });
        builder.addCase(addFreeAccessInvitation.pending, (state) => {
            state.addingNewInvitation = true;
        });
        builder.addCase(addFreeAccessInvitation.fulfilled, (state) => {
            state.addingNewInvitation = false;
        });
        builder.addCase(addFreeAccessInvitation.rejected, (state) => {
            state.addingNewInvitation = false;
        });
        builder.addCase(fetchProductOrderByEmail.pending, (state, { meta }) => {
            state.productOrdersStatus = RequestStatus.FETCHING;
            state.productOrdersClientEmail = meta.arg.email;
            state.productOrders = [];
        });
        builder.addCase(fetchProductOrderByEmail.fulfilled, (state, { payload }) => {
            state.productOrders = payload;
            state.isLastOrdersPage = payload.length < state.productOrdersListSize;
            state.productOrdersPage = 1;
            state.productOrdersStatus = RequestStatus.SUCCESS;
        });
        builder.addCase(fetchProductOrderByEmail.rejected, (state) => {
            state.productOrdersStatus = RequestStatus.FAILED;
            state.productOrders = [];
        });
        builder.addCase(fetchNextProductOrdersByEmail.pending, (state) => {
            state.productOrdersStatus = RequestStatus.UPDATING;
        });
        builder.addCase(fetchNextProductOrdersByEmail.fulfilled, (state, { payload }) => {
            state.productOrdersStatus = RequestStatus.SUCCESS;
            state.productOrdersPage = payload.page;
            state.isLastOrdersPage = payload.isLastPage;
            state.productOrders = [...state.productOrders, ...payload.list];
        });
        builder.addCase(fetchNextProductOrdersByEmail.rejected, (state) => {
            state.productOrdersStatus = RequestStatus.FAILED;
        });
        builder.addCase(getCheckoutProductInfo.pending, (state) => {
            state.checkoutProductInfoStatus = RequestStatus.FETCHING;
            state.checkoutProductInfo = null;
        });
        builder.addCase(getCheckoutProductInfo.fulfilled, (state, { payload }) => {
            state.checkoutProductInfoStatus = RequestStatus.SUCCESS;
            state.checkoutProductInfo = payload;
        });
        builder.addCase(getCheckoutProductInfo.rejected, (state) => {
            state.checkoutProductInfoStatus = RequestStatus.FAILED;
        });
        builder.addCase(checkAccessToProduct.pending, (state) => {
            state.checkAccessInfo = null;
            state.checkAccessInfoStatus = RequestStatus.FETCHING;
        });
        builder.addCase(checkAccessToProduct.fulfilled, (state, { payload }) => {
            state.checkAccessInfo = payload;
            state.checkAccessInfoStatus = RequestStatus.SUCCESS;
        });
        builder.addCase(checkAccessToProduct.rejected, (state) => {
            state.checkAccessInfoStatus = RequestStatus.FAILED;
        });
        builder.addCase(searchProductClient.pending, (state, action) => {
            state.searchTerm = action.meta.arg.emailOrLastName;
            state.searchStatus = RequestStatus.FETCHING;
        });
        builder.addCase(searchProductClient.fulfilled, (state, action) => {
            state.productClients = action.payload.filter((client) => client.status === 'active');
            state.productArchivedClients = action.payload.filter((client) => client.status === 'archived');
            state.productArchivedPage = 1;
            state.productClientsPage = 1;
            state.searchStatus = RequestStatus.SUCCESS;
        });
        builder.addCase(searchProductClient.rejected, (state) => {
            state.searchStatus = RequestStatus.FAILED;
        });
        builder.addCase(removeArchivedClient.fulfilled, (state, { payload }) => {
            state.productArchivedClients = state.productArchivedClients.filter((client) => client.email !== payload.email);
        });
        builder.addCase(updateClientDiscordId.fulfilled, (state, action) => {
            state.productClients = state.productClients.map((client) => {
                if (client.email !== action.meta.arg.payload.clientEmail) {
                    return client;
                }
                return {
                    ...client,
                    discordId: action.meta.arg.payload.discordId ?? '',
                };
            });
        });
        builder.addCase(updateProductPricesOrder.pending, (state, payload) => {
            if (state.details?.id === payload.meta.arg.productId && state.details.prices) {
                state.details.prices = uniqBy(payload.meta.arg.prices, 'id');
            }
        });
        builder.addCase(fetchProductClientImports.pending, (state) => {
            state.productClientImportsListStatus = RequestStatus.FETCHING;
            state.productClientImports = [];
        });
        builder.addCase(fetchProductClientImports.fulfilled, (state, { payload }) => {
            state.productClientImportsListStatus = RequestStatus.SUCCESS;
            state.productClientImportsPage = 1;
            state.productClientImports = payload.list;
            state.productClientTotalImports = payload.total;
            state.isLastProductClientImportsPage = payload.isLastPage;
        });
        builder.addCase(fetchProductClientImports.rejected, (state) => {
            state.productClientImportsListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchNextPageProductClientImports.pending, (state) => {
            state.productClientImportsListStatus = RequestStatus.UPDATING;
        });
        builder.addCase(fetchNextPageProductClientImports.fulfilled, (state, { payload }) => {
            state.productClientImportsListStatus = RequestStatus.SUCCESS;
            state.productClientImportsPage = payload.page;
            state.isLastProductClientImportsPage = payload.isLastPage;
            state.productClientImports = [...state.productClientImports, ...payload.list];
        });
        builder.addCase(fetchNextPageProductClientImports.rejected, (state) => {
            state.productClientImportsListStatus = RequestStatus.FAILED;
        });
    },
});
export const { subscribeToProductFailed, subscribeToProductSuccess, subscribeToProductStarted, unsubscribeFromProductDetails, clearSearchMode, } = productsSlice.actions;
export default productsSlice.reducer;
