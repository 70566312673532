import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const checkAccessToProduct = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/checkAccessToProduct`, async ({ payload: { productId }, onSuccess, onFailure }, { rejectWithValue, extra: { functions } }) => {
    try {
        const result = await functions.httpsCallable('products-checkAccess')({ productId });
        onSuccess?.();
        return result.data.body;
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
