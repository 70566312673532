import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTER_REDUCER_NAME } from '../types';
export const getProgramReferralInfo = createAsyncThunk(`${NEWSLETTER_REDUCER_NAME}/getProgramReferralInfo`, async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
        const res = await functions.httpsCallable('referrals-getReferralsInfoForClient')(payload);
        return res.data.body;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
