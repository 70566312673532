import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateResourceCategories = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateResourceCategories`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.id);
        await ref.update({
            resourceCategories: payload.categories,
        });
        analytics.track('update_resource_categories', {
            productId: payload.id,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        onSuccess?.();
        return 'test';
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
