import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const removeArchivedClient = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/removeArchivedClient`, async ({ clientOfProduct, productId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .doc(clientOfProduct.email);
        await ref.delete();
        await ref.collection(COLLECTION.PRODUCT_CLIENT_REMOVED_ACCESS).add({
            ...clientOfProduct,
            deletedAt: new Date(),
        });
        return clientOfProduct;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
