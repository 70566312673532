export const COLLECTION = {
    USERS: 'users',
    PRODUCTS: 'products',
    PRODUCTS_LIST: 'products-list',
    PRODUCT_CLIENTS: 'product-clients',
    PRODUCT_FREE_ACCESS: 'product-free-access',
    PRODUCT_RESOURCES: 'product-resources',
    PRODUCT_FILES: 'product-files',
    PRODUCT_ORDERS: 'product-orders',
    PRODUCT_CONTENT: 'product-content',
    PRODUCT_CONTENT_LESSONS: 'product-content-lessons',
    ARCHIVED_PRODUCT_ORDERS: 'archived-product-orders',
    PRODUCT_IMPORTS: 'product-imports',
    PRODUCT_IMPORT_AGGREGATIONS: 'product-imports-aggregations',
    EMAILS: 'emails',
    USERS_PRESENCE: 'users-presence',
    APPS: 'apps',
    REMINDERS: 'reminders',
    NOTIFICATIONS: 'notifications',
    SETTINGS: 'settings',
    PRODUCTS_ARCHIVE: 'products-archive',
    PRODUCT_ARCHIVE_EMAILS: 'products-archive-emails',
    PRODUCT_CONFIG: 'product-config',
    BUSINESS_EVENTS: 'business-events',
    REPORTS: 'reports',
    STATS_PER_MONTH: 'stats-per-month',
    PRODUCT_REFERRALS: 'product-referrals',
    PRODUCT_REFERRALS_CHUNKS: 'product-referrals-chunks',
    PRODUCT_CLIENT_REMOVED_ACCESS: 'product-client-removed-access',
    FAILED_INVOICE_EVENTS: 'failed-invoice-events',
    STATS_PER_PRODUCT: 'stats-per-product',
    STATS_PER_PRODUCT_MONTH: 'stats-per-product-month',
    USER_SUBSCRIPTIONS: 'subscriptions',
    CHECKOUT_PAYMENT_INTENTS: 'checkout-payment-intents',
    PRODUCT_COURSE_PROGRESS: 'product-course-progress',
    PRODUCT_COURSE_NOTES: 'product-course-notes',
    NFT_COLLECTIONS_LISTENERS: 'nft-collections-listeners',
    NFT_COLLECTIONS_TOKENS_LISTENERS: 'nft-collections-tokens-listeners',
    NFT_CONNECTED_WITH_PRODUCTS: 'nft-connected-with-products',
    NFT_WEBHOOKS_EVENTS: 'nft-webhooks-events',
    AUTOMATION_LOGS: 'automation-logs',
    REFERRAL_CODES: 'referral-codes',
    REFERRAL_REGISTRATIONS: 'referral-registrations',
    API_TOKENS: 'api-tokens',
    DISCOUNTS: 'discounts',
    COMMUNITIES: 'communities',
    COMMUNITY_MEMBERS: 'community-members',
};
