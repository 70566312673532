import { createSelector } from '@reduxjs/toolkit';
import { getClientsProgress as getClientsProgressHelper } from '../../../../helpers/getClientsProgress';
const getProductContent = (store) => store.productContent.data;
const getProductCourseProgressList = (store) => store.product.productCourseProgress;
export const getClientsProgress = createSelector([getProductContent, getProductCourseProgressList], (productContent, productCourseProgressList) => {
    if (!productContent || !productCourseProgressList) {
        return [];
    }
    return getClientsProgressHelper(productContent, productCourseProgressList);
});
