import React from 'react';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    minHeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const CheckoutFailure = () => {
  const { t } = useTranslation('checkout');
  const queryParams = new URLSearchParams(window.location.search);

  return (
    <div style={styles.container}>
      <Result
        status="error"
        title={t<string>('failure.title')}
        subTitle={t<string>('failure.desc')}
        extra={[
          <Link
            key="link"
            to={`/checkout/${queryParams.get('accountId')}?priceId=${queryParams.get(
              'priceId'
            )}&productId=${queryParams.get('productId')}&type=${
              queryParams.get('type') || 'service'
            }&name=${queryParams.get('name')}`}
          >
            <Button type="primary">{t<string>('button.tryAgain')}</Button>
          </Link>,
        ]}
      />
    </div>
  );
};

export default CheckoutFailure;
