import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
import { EmailClient, FIRESTORE_COLLECTION, FIRESTORE_DOCUMENT } from '../../../../index';
export const skipMailersIntegration = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/skipMailersIntegration`, async ({ onSuccess, onFailure }, { rejectWithValue, extra: { firestore, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('User is not logged');
        }
        const ref = firestore()
            .collection(FIRESTORE_COLLECTION.USERS)
            .doc(user.uid)
            .collection(FIRESTORE_COLLECTION.SETTINGS)
            .doc(FIRESTORE_DOCUMENT.INTEGRATION_CONFIG);
        await ref.update({
            mailers: {
                [EmailClient.ActiveCampaign]: [],
            },
        });
        analytics.track('skip_mailers_integration', {
            userId: user.uid,
            email: user.email,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
