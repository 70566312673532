import { createSlice } from '@reduxjs/toolkit';
import { DISCOUNT_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
import { updateDiscount } from './actions';
import { fetchDiscounts } from './actions';
const initialState = {
    discounts: [],
    discountsListStatus: null,
    discountAction: null,
    discountsError: null,
};
const discountSlice = createSlice({
    name: DISCOUNT_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToDiscountsStarted(state) {
            state.discountsListStatus = RequestStatus.SUBSCRIBING;
            state.discountsError = null;
        },
        subscribeToDiscountsSuccess(state, { payload }) {
            state.discountsListStatus = RequestStatus.SUBSCRIBED;
            state.discounts = payload;
        },
        subscribeToDiscountsFailed(state, { payload }) {
            state.discountsListStatus = RequestStatus.FAILED;
            state.discountsError = payload;
        },
        unsubscribeFromDiscounts(state) {
            state.discountsListStatus = null;
            state.discounts = [];
            state.discountsError = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateDiscount.pending, (state, { meta }) => {
            state.discountAction = meta.arg.payload
                ? {
                    id: meta.arg.payload.id,
                    action: meta.arg.payload.action,
                }
                : null;
        });
        builder.addCase(updateDiscount.fulfilled, (state) => {
            state.discountAction = null;
        });
        builder.addCase(updateDiscount.rejected, (state) => {
            state.discountAction = null;
        });
        builder.addCase(fetchDiscounts.pending, (state) => {
            state.discountsListStatus = RequestStatus.FETCHING;
            state.discounts = [];
        });
        builder.addCase(fetchDiscounts.rejected, (state) => {
            state.discountsListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchDiscounts.fulfilled, (state, { payload }) => {
            state.discountsListStatus = RequestStatus.SUCCESS;
            state.discounts = payload.list;
        });
    },
});
export const { subscribeToDiscountsFailed, subscribeToDiscountsStarted, subscribeToDiscountsSuccess, unsubscribeFromDiscounts, } = discountSlice.actions;
export default discountSlice.reducer;
