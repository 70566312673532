import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from '../reducer';
export const addCreatorToMailingList = createAsyncThunk(`${USER_REDUCER}/addCreatorToMailingList`, async (payload, { extra: { config } }) => {
    const creatorListProductId = payload.country === 'PL'
        ? config?.CREATOR_MAILING_LIST_ID_PL
        : config?.CREATOR_MAILING_LIST_ID_GLOBAL;
    if (!creatorListProductId || !config?.CHECKOUT_URL) {
        return Promise.resolve();
    }
    try {
        await fetch(config?.CHECKOUT_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...payload,
                productId: creatorListProductId,
                priceId: 'free',
            }),
        });
    }
    catch {
        // do nothing
        // later add log to sentry
    }
});
