import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const updateProductSlug = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateProductSlug`, async (payload, { extra: { functions, analytics, auth } }) => {
    const user = auth().currentUser;
    if (!user) {
        throw new Error('User is not logged');
    }
    await functions.httpsCallable('products-updateProductSlug')(payload);
    analytics.track('update_product_slug', {
        user_id: user.uid,
        email: user.email,
        slug: payload.slug ?? '',
        product_id: payload.productId,
    });
});
