import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_FILES_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const updateProductFiles = createAsyncThunk(`${PRODUCT_FILES_REDUCER_NAME}/updateProductFiles`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('invalid-user');
        }
        const model = {
            ...payload.model,
            updatedAt: new Date(),
        };
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_FILES)
            .doc(FIRESTORE_DOCUMENT.PRODUCT_FILES_CONTENT);
        await ref.update(model);
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
