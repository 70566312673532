import React, { FunctionComponent, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import * as styles from './Checkout.module.scss';
import { Card, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '~/hooks/useQuery';

interface OwnProps {}

type Props = OwnProps;

const CheckoutFreeSuccess: FunctionComponent<Props> = () => {
  const { t } = useTranslation('checkout');
  const queryParams = useQuery();
  const productType = queryParams.get('type') || 'service';
  const productName = queryParams.get('name');

  useEffect(() => {
    firebase.analytics().logEvent('product_purchased', {
      email: queryParams.get('email'),
      productName,
      productId: queryParams.get('productId'),
    });
  }, [productName, queryParams]);

  return (
    <div className={styles.backgroundAlert}>
      <Card
        className={styles.infoCard}
        title={t<string>('checkout:freeTitle', { name: productName })}
      >
        <Result
          status="success"
          title={t<string>('success.freeTitle')}
          subTitle={
            <p className={styles.emptyLineBefore}>
              {productType === 'service'
                ? t<string>('success.freeDesc')
                : t<string>('success.goodDesc')}
            </p>
          }
        />
      </Card>
    </div>
  );
};

export default CheckoutFreeSuccess;
