import { logInFailed, logInStarted, logInSuccess } from '../reducer';
import { getUserDetails } from './getUserDetails';
export const loginByEmailLink = ({ href, email }, callback, onFailure) => async (dispatch, _, { auth, analytics }) => {
    dispatch(logInStarted());
    try {
        const res = await auth().signInWithEmailLink(email, href);
        if (!res.user) {
            throw new Error('wrong-login-data');
        }
        const { uid, email: userEmail } = res.user;
        dispatch(logInSuccess({
            uid,
            email: userEmail,
        }));
        analytics.identify(uid, { email: userEmail });
        dispatch(getUserDetails(uid));
        if (callback) {
            callback();
        }
    }
    catch (e) {
        onFailure?.(e.code, e.message);
        dispatch(logInFailed(e.code));
    }
};
