import { createAsyncThunk } from '@reduxjs/toolkit';
import { SUBSCRIPTION_REDUCER_NAME } from '../types';
export const updateUserInvoiceData = createAsyncThunk(`${SUBSCRIPTION_REDUCER_NAME}/updateUserInvoiceData`, async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
        await functions.httpsCallable('subscriptions-onCustomerDataUpdate')(payload);
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
