export var ProductReferralsStatus;
(function (ProductReferralsStatus) {
    ProductReferralsStatus["Active"] = "active";
    ProductReferralsStatus["Inactive"] = "inactive";
    ProductReferralsStatus["Suspended"] = "suspended";
})(ProductReferralsStatus || (ProductReferralsStatus = {}));
export var RewardType;
(function (RewardType) {
    RewardType["Digital"] = "digital";
    RewardType["Physical"] = "physical";
    RewardType["CouponCode"] = "couponCode";
})(RewardType || (RewardType = {}));
export var ApprovalType;
(function (ApprovalType) {
    ApprovalType["Automatically"] = "automatically";
    ApprovalType["Manual"] = "manual";
})(ApprovalType || (ApprovalType = {}));
export var RewardStatus;
(function (RewardStatus) {
    RewardStatus["Active"] = "active";
    RewardStatus["Inactive"] = "inactive";
})(RewardStatus || (RewardStatus = {}));
export var EmailValidationStatus;
(function (EmailValidationStatus) {
    EmailValidationStatus["Valid"] = "valid";
    EmailValidationStatus["Invalid"] = "invalid";
    EmailValidationStatus["Disposable"] = "disposable";
    EmailValidationStatus["Catchall"] = "catchall";
    EmailValidationStatus["Unknown"] = "unknown";
    EmailValidationStatus["Duplicated"] = "duplicated";
    EmailValidationStatus["DuplicatedWithAlias"] = "duplicatedWithAlias";
})(EmailValidationStatus || (EmailValidationStatus = {}));
