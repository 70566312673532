import { createAsyncThunk } from '@reduxjs/toolkit';
import { ARCHIVE_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const removeArchivedEmail = createAsyncThunk(`${ARCHIVE_REDUCER_NAME}/removeArchivedEmail`, async (payload, { extra: { db, auth, analytics } }) => {
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    const ref = db
        .collection(COLLECTION.PRODUCTS_ARCHIVE)
        .doc(payload.productId)
        .collection(COLLECTION.PRODUCT_ARCHIVE_EMAILS)
        .doc(payload.archivedEmailId);
    await ref.delete();
    await analytics.track('removed_archived_email', {
        productId: payload.productId,
        userId: user.uid,
    });
    return {
        id: payload.archivedEmailId,
    };
});
