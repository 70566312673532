import { createAsyncThunk } from '@reduxjs/toolkit';
import { DISCOUNT_REDUCER_NAME } from '../types';
export const updateDiscount = createAsyncThunk(`${DISCOUNT_REDUCER_NAME}/updateDiscount`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        await functions.httpsCallable('discounts-updateDiscount')(payload);
        analytics.track('update-discount', {
            discountId: payload.id,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
