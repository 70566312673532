import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { createRootReducer } from './reducers';
const createStore = (storeDependencies) => {
    const middleware = [];
    const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
    if (IS_DEVELOPMENT) {
        middleware.push(createLogger({ collapsed: true }));
    }
    const store = configureStore({
        reducer: createRootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
            thunk: {
                extraArgument: {
                    ...storeDependencies,
                    firestoreSubscriptions: {},
                },
            },
        }).concat(middleware),
    });
    return { store };
};
export default createStore;
