import { FunctionComponent } from 'react';
import 'firebase/compat/analytics';

const CHECKOUT_URL = import.meta.env.VITE_EMBEDDED_FORM_DOMAIN as string;

const newCheckoutUrl = (slug: string | null, priceId: string | null, ref: string | null) => {
  const baseUrl = new URL(CHECKOUT_URL);
  baseUrl.pathname = `/c/${slug}`;
  if (priceId) {
    baseUrl.searchParams.append('priceId', priceId);
  }
  if (ref) {
    baseUrl.searchParams.append('ref', ref);
  }
  return baseUrl.toString();
};

const RedirectToNewCheckout: FunctionComponent = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get('productId');
  const defaultPrice = queryParams.get('priceId');
  const ref = queryParams.get('ref');

  window.location.replace(newCheckoutUrl(productId, defaultPrice, ref));

  return null;
};

export default RedirectToNewCheckout;
