import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchProductOrderByEmail = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchProductOrderByEmail`, async ({ productId, email, ownerId }, { rejectWithValue, getState, extra: { auth, db, firestoreSubscriptions } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const { productOrdersListSize } = getState().product;
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_ORDERS)
            .where('clientEmail', '==', email)
            .orderBy('createdAt', 'desc')
            .limit(productOrdersListSize);
        const querySnapshot = await ref.get();
        if (!querySnapshot.empty) {
            firestoreSubscriptions.lastOrderCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
        return querySnapshot.docs.map((doc) => firestoreDateMapper(doc));
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
