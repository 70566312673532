import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
export const generateLinkForIntegration = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/generateLinkForIntegration`, async ({ onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        const res = await functions.httpsCallable('products-createStripeIntegrationLink')();
        window.open(res.data.body);
        analytics.track('generate_stripe_integration_link', {
            userId: user.uid,
            email: user.email,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
