import { resetPasswordFailed, resetPasswordStarted, resetPasswordSuccess } from '../reducer';
export const resetUserPassword = ({ resetPasswordCode, password }, callback) => async (dispatch, getState, { auth }) => {
    dispatch(resetPasswordStarted());
    try {
        await auth().confirmPasswordReset(resetPasswordCode, password);
        if (callback) {
            callback();
        }
        dispatch(resetPasswordSuccess());
    }
    catch (e) {
        dispatch(resetPasswordFailed(e.code));
    }
};
