import { createSelector } from '@reduxjs/toolkit';
const getNewsletters = (store) => store.newsletters.list;
const getSearchTerm = (store) => store.newsletters.searchTerm;
export const getFilteredNewsletters = createSelector([getNewsletters, getSearchTerm], (newsletters, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
        return newsletters;
    }
    const searchInLowerCase = searchTerm.toLowerCase();
    return newsletters.filter((newsletter) => {
        if (newsletter.product.name.toLowerCase().includes(searchInLowerCase)) {
            return true;
        }
        if (newsletter.author.email.toLowerCase().includes(searchInLowerCase)) {
            return true;
        }
        if (newsletter.author.lastName.toLowerCase().includes(searchInLowerCase)) {
            return true;
        }
        return false;
    });
});
