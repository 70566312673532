import { createSelector } from '@reduxjs/toolkit';
const getData = (store) => store.communities.data;
const getSearchTerm = (store) => store.communities.searchTerm;
export const getFilteredCommunities = createSelector([getData, getSearchTerm], (products, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
        return products;
    }
    const searchInLowerCase = searchTerm.toLowerCase();
    return products.filter((product) => {
        return product.name.toLowerCase().includes(searchInLowerCase);
    });
});
