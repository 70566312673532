import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateProductPricesOrder = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateProductPricesOrder`, async (payload, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const { product } = getState();
        if (product.details?.id !== payload.productId) {
            throw new Error('different-product-id');
        }
        if (!product.details.prices || !product.details.prices.length) {
            throw new Error('product-without-prices');
        }
        const payloadPriceIds = new Set(payload.prices.map((price) => price.id));
        if (product.details.prices.length !== payload.prices.length ||
            !product.details.prices.every((price) => payloadPriceIds.has(price.id))) {
            throw new Error('product-prices-mismatch');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId);
        await ref.update({
            prices: payload.prices,
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
