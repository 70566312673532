import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { UserRole } from '../../../../enums/userRole';
import { addCreatorToMailingList } from './addCreatorToMailingList';
async function getCountry(functions) {
    try {
        const result = await functions.httpsCallable('users-getUserMetadata')();
        return result.data.body.country;
    }
    catch (e) {
        return null;
    }
}
export const acceptCreatorTerms = createAsyncThunk(`${USER_REDUCER}/acceptCreatorTerms`, async (ip, { dispatch, rejectWithValue, getState, extra: { firestore, functions, auth } }) => {
    try {
        const user = await auth().currentUser;
        if (!user || !user.email) {
            throw new Error('User is not logged');
        }
        const details = getState().user.details;
        await firestore()
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .update({
            allowedRoles: [UserRole.Creator, UserRole.Subscriber],
            termsAndPolicyAcceptDate: firestore.FieldValue.serverTimestamp(),
            updatedAt: firestore.FieldValue.serverTimestamp(),
            ip,
        });
        await dispatch(addCreatorToMailingList({
            firstName: details?.firstName ?? '',
            lastName: details?.lastName ?? '',
            email: user.email,
            country: details?.country ?? (await getCountry(functions)) ?? 'PL',
        }));
    }
    catch (e) {
        if (e instanceof Error) {
            rejectWithValue(e.message);
        }
        rejectWithValue('Unknown error');
    }
});
