import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
import { cloudFunctionErrorHandler } from '../../../../helpers/cloudFunctionErrorHandler';
export const refreshConnectedListsWithEmailClient = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/refreshListsConnectedWithEmailClient`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-refreshListsConnectedWithEmailClient')(payload);
        analytics.track('refresh_connected_lists_with_email_client', {
            userId: user.uid,
            email: user.email,
            client: payload.client,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.(cloudFunctionErrorHandler(e).code);
        return rejectWithValue(e);
    }
});
