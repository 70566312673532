import React, { lazy, Suspense, useEffect } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { store } from '~/initializeStore';
import AuthChecker from './components/AuthChecker/AuthChecker';
import Auth from './pages/Auth/Auth';
import ProtectedRoute from '~/components/ProtectedRoute/ProtectedRoute';
import CheckoutSuccess from '~/pages/Checkout/CheckoutSuccess';
import CheckoutFailure from '~/pages/Checkout/CheckoutFailure';
import RedirectToNewCheckout from '~/pages/Checkout/RedirectToNewCheckout';
import CheckoutFreeProduct from '~/pages/Checkout/CheckoutFreeProduct';
import CheckoutFreeSuccess from '~/pages/Checkout/CheckoutFreeSuccess';
import CheckoutRenewal from '~/pages/Checkout/CheckoutRenewal';
import { useTranslation } from 'react-i18next';
import FrameRoutes from '~/FrameRoutes';
import FrameLogOut from '~/pages/FrameAuth/FrameLogOut';
import CheckoutPending from '~/pages/Checkout/CheckoutPending';
import { ProductPaywall } from '~/pages/Paywall/ProductPaywall';
import FullPageLoader from '~/components/FullPageLoader/FullPageLoader';

const Dashboard = lazy(() => import('~/pages/Dashboard/Dashboard'));

const AppRoutes = () => (
  <AuthChecker>
    <Switch>
      <Route exact path="/sign-with-link" component={CheckoutSuccess} />
      <Route exact path="/checkout/success" component={CheckoutSuccess} />
      <Route exact path="/checkout/pending" component={CheckoutPending} />
      <Route exact path="/checkout/failure" component={CheckoutFailure} />
      <Route exact path="/checkout/:accountId" component={RedirectToNewCheckout} />
      <Route exact path="/checkout/:accountId/renewal" component={CheckoutRenewal} />
      <Route exact path="/free/success" component={CheckoutFreeSuccess} />
      <Route exact path="/free/:accountId" component={CheckoutFreeProduct} />
      <Route path="/auth" component={Auth} />
      <Route path="/access/:productId" component={ProductPaywall} />
      <ProtectedRoute path="/" component={Dashboard} />
    </Switch>
  </AuthChecker>
);

const notFrame = (/^(?!.*(\/frame)).*$/ as unknown) as string;

const Routes = () => {
  return (
    <>
      <Suspense fallback={<FullPageLoader />}>
        <Route exact path="/frame/logout" component={FrameLogOut} />
        <Route path="/frame" component={FrameRoutes} />
        <Route path={notFrame} component={AppRoutes} />
      </Suspense>
    </>
  );
};

function App() {
  const { i18n } = useTranslation('common');
  useEffect(() => {
    if (['pl', 'en'].includes(i18n.language) || !i18n.language) {
      return;
    }
    const [lang] = i18n.language.split('-');
    i18n.changeLanguage(lang || 'en');
  }, [i18n]);

  return (
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
