import { PRODUCT_RESOURCES_REDUCER_NAME } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    status: null,
    data: [],
    error: null,
    searchTerm: null,
};
const productResourcesSlice = createSlice({
    name: PRODUCT_RESOURCES_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductResourcesStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
            state.error = null;
            state.data = [];
        },
        subscribeToProductResourcesSuccess(state, { payload }) {
            state.status = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToProductResourcesFailed(state, { payload }) {
            state.status = RequestStatus.FAILED;
            state.error = payload.message;
        },
        unsubscribeFromProductResources(state) {
            state.status = null;
            state.data = [];
            state.error = null;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
});
export const { subscribeToProductResourcesStarted, subscribeToProductResourcesSuccess, subscribeToProductResourcesFailed, unsubscribeFromProductResources, clearSearchMode, setSearchTerm, } = productResourcesSlice.actions;
export default productResourcesSlice.reducer;
