import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { v4 as uuid } from 'uuid';
import { SubscriberIntegrationClient } from '../../../../enums/SubscriberIntegrationClient';
import { createClientDiscordIntegrationLink } from '../../../../helpers/createDiscordIntegrationLink';
export const requestClientDiscordIntegration = createAsyncThunk(`${USER_REDUCER}/requestClientDiscordIntegration`, async (_, { rejectWithValue, getState, extra: { firestore, auth, config } }) => {
    try {
        const user = await auth().currentUser;
        if (!user || !user.email) {
            throw new Error('User is not logged');
        }
        if (!config) {
            throw new Error('Config is not provided');
        }
        const userDetails = getState().user.details;
        const data = userDetails?.clientIntegrations?.discord
            ? {
                state: userDetails.clientIntegrations.discord.state,
                createdAt: new Date(),
                status: 'requested',
                updatedAt: new Date(),
                source: 'panel',
            }
            : {
                state: uuid(),
                status: 'requested',
                createdAt: new Date(),
                updatedAt: new Date(),
                source: 'panel',
            };
        await firestore()
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .update({
            [`clientIntegrations.${SubscriberIntegrationClient.Discord}`]: data,
        });
        const link = createClientDiscordIntegrationLink({
            state: data.state,
            clientId: config.DISCORD_MAILINGR_CLIENT_ID,
            functionDomain: config.APP_FUNCTION_DOMAIN,
        });
        return link;
    }
    catch (e) {
        if (e instanceof Error) {
            throw rejectWithValue(e.message);
        }
        throw rejectWithValue('Unknown error');
    }
});
