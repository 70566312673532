import { PRODUCT_AUTOMATION_REDUCER_NAME } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    status: null,
    data: null,
    error: null,
};
const productAutomationSlice = createSlice({
    name: PRODUCT_AUTOMATION_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductAutomationStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
            state.error = null;
            state.data = null;
        },
        subscribeToProductAutomationSuccess(state, { payload }) {
            state.status = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToProductAutomationFailed(state, { payload }) {
            state.status = RequestStatus.FAILED;
            state.error = payload.message;
        },
        unsubscribeFromProductAutomation(state) {
            state.status = null;
            state.data = null;
            state.error = null;
        },
    },
});
export const { subscribeToProductAutomationFailed, subscribeToProductAutomationStarted, subscribeToProductAutomationSuccess, unsubscribeFromProductAutomation, } = productAutomationSlice.actions;
export default productAutomationSlice.reducer;
