import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import { LANGUAGE, translations } from '@mailingr/shared';

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: ['en', LANGUAGE],
    defaultNS: 'common',
    ns: ['common', 'auth'],
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
