import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const fetchNextPageProductCourseProgress = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchNextPageProductCourseProgress`, async ({ productId, page }, { extra: { db, firestoreSubscriptions }, getState }) => {
    const { productCourseProgress, productCourseProgressSize, productCourseProgressPage, isLastPageForProgress, } = getState().product;
    if (isLastPageForProgress || (productCourseProgressPage && page <= productCourseProgressPage)) {
        return {
            page,
            list: [],
            isLastPage: isLastPageForProgress,
        };
    }
    const countPages = page - Math.ceil(productCourseProgress.length / productCourseProgressSize);
    if (countPages <= 0) {
        return {
            page,
            list: [],
            isLastPage: isLastPageForProgress,
        };
    }
    const ref = db
        .collection(COLLECTION.PRODUCT_COURSE_PROGRESS)
        .where('productId', '==', productId)
        .orderBy('numberOfSessions', 'desc')
        .limit(productCourseProgressSize * (page - productCourseProgressPage))
        .startAfter(firestoreSubscriptions.lastProductCourseProgressCursor)
        .withConverter(getDocumentConverter());
    const snapshot = await ref.get();
    const isLastPage = snapshot.docs.length < productCourseProgressSize;
    firestoreSubscriptions.lastProductCourseProgressCursor = isLastPage
        ? null
        : snapshot.docs[snapshot.docs.length - 1];
    const list = snapshot.docs.map((doc) => doc.data());
    const currentPage = productCourseProgressPage || 1;
    return {
        list,
        isLastPage,
        page: page - currentPage,
    };
});
