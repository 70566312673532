import React, { FunctionComponent, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import * as styles from './Checkout.module.scss';
import { Card, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '~/hooks/useQuery';

interface OwnProps {}

type Props = OwnProps;

const CheckoutPending: FunctionComponent<Props> = () => {
  const { t } = useTranslation('checkout');
  const queryParams = useQuery();
  const failed = queryParams.get('redirect_status') === 'failed';
  const history = useHistory();

  useEffect(() => {
    if (failed) {
      history.push(
        `/checkout/failure?priceId=${queryParams.get('priceId')}&productId=${queryParams.get(
          'productId'
        )}}`
      );
    }

    const email = queryParams.get('email')?.replace(' ', '+');

    if (email) {
      window.localStorage.setItem('emailForSignIn', email);
    }

    firebase.analytics().logEvent('transaction_pending', {
      email,
      productName: queryParams.get('name'),
      productId: queryParams.get('productId'),
    });
  }, [failed, history, queryParams]);
  return (
    <div className={styles.background}>
      <Card className={styles.infoCard} title={t<string>('pending.title')}>
        <Result
          status="info"
          title={t<string>('pending.message')}
          subTitle={<p className={styles.emptyLineBefore}>{t<string>('pending.desc')}</p>}
          extra={<p className={styles.subtitle}>{t<string>('pending.explanation')}</p>}
        />
      </Card>
    </div>
  );
};

export default CheckoutPending;
