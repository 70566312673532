import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchNextProductOrdersByEmail = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchNextProductOrdersByEmail`, async ({ productId, email, ownerId, page }, { rejectWithValue, getState, extra: { auth, db, firestoreSubscriptions } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const { productOrdersListSize, isLastOrdersPage: isFetchedAllItems, productOrdersPage, } = getState().product;
        if (isFetchedAllItems || (productOrdersPage && page <= productOrdersPage)) {
            return {
                page,
                list: [],
                isLastPage: isFetchedAllItems,
            };
        }
        const countPages = page - productOrdersPage;
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_ORDERS)
            .where('clientEmail', '==', email)
            .orderBy('createdAt', 'desc')
            .limit(countPages * productOrdersListSize)
            .startAfter(firestoreSubscriptions.lastOrderCursor);
        const querySnapshot = await ref.get();
        const isLastPage = querySnapshot.docs.length < productOrdersListSize;
        firestoreSubscriptions.lastOrderCursor = isLastPage
            ? null
            : querySnapshot.docs[querySnapshot.docs.length - 1];
        return {
            list: querySnapshot.docs.map((doc) => firestoreDateMapper(doc)),
            page,
            isLastPage,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
