import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
export const deleteLessonVideo = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/deleteLessonVideo`, async (dto, { extra: { analytics, auth, functions } }) => {
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    await functions.httpsCallable('videos-deleteVideoFromLesson')(dto);
    analytics.track('delete_video_in_lesson', {
        ownerId: user.uid,
        productId: dto.productId,
        moduleId: dto.moduleId,
    });
});
