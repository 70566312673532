import { createAsyncThunk } from '@reduxjs/toolkit';
import { SUBSCRIPTION_REDUCER_NAME } from '../types';
export const createBillingCustomerSession = createAsyncThunk(`${SUBSCRIPTION_REDUCER_NAME}/createBillingCustomerSession`, async (_, { rejectWithValue, extra: { functions } }) => {
    try {
        const res = await functions.httpsCallable('subscriptions-createBillingCustomerSession')();
        return res.data?.body;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
