import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTERS_REDUCER_NAME } from '../../newsletters/types';
export const issueInvoice = createAsyncThunk(`${NEWSLETTERS_REDUCER_NAME}/issueInvoice`, async (payload, { rejectWithValue, extra: { functions, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        await functions.httpsCallable('invoices-retryInvoiceIssueForOrder')(payload);
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
