export var RenewalAccessPeriodStatus;
(function (RenewalAccessPeriodStatus) {
    RenewalAccessPeriodStatus["InProgress"] = "inProgress";
    RenewalAccessPeriodStatus["Finished"] = "finished";
})(RenewalAccessPeriodStatus || (RenewalAccessPeriodStatus = {}));
export var ClientArchiveReason;
(function (ClientArchiveReason) {
    ClientArchiveReason["AccessEnd"] = "access_end";
    ClientArchiveReason["Refund"] = "refund";
    ClientArchiveReason["Unsubscribe"] = "unsubscribe";
    ClientArchiveReason["NFTSell"] = "nft_sell";
})(ClientArchiveReason || (ClientArchiveReason = {}));
export const productClientStatus = ['active', 'archived'];
