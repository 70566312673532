export function firestoreDateMapper(doc, mapper) {
    if (!doc.exists) {
        throw new Error('Document not exists');
    }
    const data = mapper ? mapper(doc.data()) : doc.data();
    return {
        ...data,
        id: doc.id,
        createdAt: data.createdAt ? data.createdAt.toDate?.() : new Date(),
        updatedAt: data.updatedAt ? data.updatedAt.toDate?.() : new Date(),
    };
}
function transformFirestoreTimestampIntoDateInCollection(document, mapper) {
    const data = mapper ? mapper(document.data()) : document.data();
    return {
        id: document.id,
        ...data,
        createdAt: 'createdAt' in data ? data.createdAt?.toDate() : null,
        updatedAt: 'updatedAt' in data ? data.updatedAt?.toDate() : null,
    };
}
export function getDocumentConverter(mapper) {
    return {
        toFirestore: (document) => document,
        fromFirestore: (document) => transformFirestoreTimestampIntoDateInCollection(document, mapper),
    };
}
