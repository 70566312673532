import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTER_REDUCER_NAME } from '../types';
export const cancelNewsletterSubscriptionAtCurrentPeriodEnd = createAsyncThunk(`${NEWSLETTER_REDUCER_NAME}/cancelNewsletterSubscriptionAtCurrentPeriodEnd`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions } }) => {
    try {
        const res = await functions.httpsCallable('newsletters-cancelNewsletterAtEndCurrentSubscription')(payload);
        onSuccess?.(res.data?.body);
        return res.data;
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
