import { createSelector } from '@reduxjs/toolkit';
const getProductContent = (store) => store.productContent.data;
export const getCourseTags = createSelector([getProductContent], (content) => {
    if (!content) {
        return [];
    }
    const lessons = content.modules.map((module) => module.lessons).flat();
    const tags = lessons.map((lesson) => lesson.tags ?? []).flat();
    return [...new Set(tags)];
});
