import { logInFailed, logInStarted, logInSuccess } from '../reducer';
import { getUserDetails } from './getUserDetails';
import { UserStatus } from '../types';
export const logInUser = ({ email, password }, callback) => async (dispatch, getState, { auth, analytics }) => {
    const { user } = getState();
    if (user.status === UserStatus.LOGGING_IN || user.data?.email === email) {
        return;
    }
    dispatch(logInStarted());
    try {
        const res = await auth().signInWithEmailAndPassword(email, password);
        if (!res.user) {
            return;
        }
        const { uid, email: userEmail } = res.user;
        dispatch(logInSuccess({
            uid,
            email: userEmail,
        }));
        analytics.identify(uid, { email: userEmail });
        dispatch(getUserDetails(uid));
        if (callback) {
            callback();
        }
    }
    catch (e) {
        dispatch(logInFailed(e.code));
    }
};
