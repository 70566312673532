import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const updateProduct = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateProduct`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        await functions.httpsCallable('products-updateProduct')(payload);
        analytics.track('update_product', {
            productId: payload.id,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
