import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const setExtendAccessPeriod = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/setExtendAccessPeriod`, async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        await functions.httpsCallable('products-extendClientAccessToProduct')(payload);
        analytics.track('extend_access_period', {
            productId: payload.productId,
            authorId: user?.uid ?? '',
            clientEmail: payload.clientEmail,
            extendAbout: payload.resume_at,
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
