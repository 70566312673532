import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../..';
export const fetchNextPageFreeAccessInvitation = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchNextPageFreeAccessInvitation`, async ({ productId, page }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const { freeAccessListSize, freeAccessIsLastPage, freeAccessPage, freeAccessList, } = getState().product;
        if (freeAccessIsLastPage || page <= freeAccessPage) {
            return {
                list: [],
                isLastPage: freeAccessIsLastPage,
                page,
            };
        }
        const countPages = page - Math.ceil(freeAccessList.length / freeAccessListSize);
        if (countPages <= 0) {
            return {
                list: [],
                page,
                isLastPage: freeAccessIsLastPage,
            };
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS)
            .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_FREE_ACCESS)
            .orderBy('createdAt', 'desc')
            .limit(freeAccessListSize)
            .startAfter(firestoreSubscriptions.lastFreeAccessInvitationCursor);
        const res = await ref.get();
        const list = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const isLastPage = list.length < freeAccessListSize;
        if (!res.empty) {
            firestoreSubscriptions.lastFreeAccessInvitationCursor = isLastPage
                ? null
                : res.docs[res.docs.length - 1];
        }
        return {
            list,
            isLastPage,
            page,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
