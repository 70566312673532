import { createAsyncThunk } from '@reduxjs/toolkit';
import orderBy from 'lodash.orderby';
import capitalize from 'lodash.capitalize';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import uniqBy from 'lodash.uniqby';
export const searchProductClient = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/searchProductClient`, async ({ emailOrLastName, productId, ownerId }, { rejectWithValue, extra: { auth, db }, getState }) => {
    try {
        const { productClientsListSize } = getState().product;
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const refEmail = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .where('email', '>=', emailOrLastName)
            .where('email', '<=', emailOrLastName + '~')
            .limit(productClientsListSize);
        const refName = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .where('lastName', '>=', emailOrLastName)
            .where('lastName', '<=', emailOrLastName + '~')
            .limit(productClientsListSize);
        const refCaptalizeName = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .where('lastName', '>=', capitalize(emailOrLastName))
            .where('lastName', '<=', capitalize(emailOrLastName) + '~')
            .limit(productClientsListSize);
        const res = await Promise.all([refEmail.get(), refName.get(), refCaptalizeName.get()]);
        const list = res
            .map((snapshot) => snapshot.docs)
            .flat()
            .map((doc) => firestoreDateMapper(doc));
        return orderBy(uniqBy(list, 'email'), 'createdAt', 'desc');
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
