import { createSlice } from '@reduxjs/toolkit';
import { AUTOMATION_LOGS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    selectedClient: null,
    clientAutomationLogs: [],
    clientAutomationLogsStatus: null,
};
const reducerSlice = createSlice({
    initialState,
    name: AUTOMATION_LOGS_REDUCER_NAME,
    reducers: {
        subscribeToClientLogsStarted(state, { payload }) {
            state.clientAutomationLogsStatus = RequestStatus.SUBSCRIBING;
            state.selectedClient = payload;
        },
        subscribeToClientLogsSuccess(state, { payload }) {
            state.clientAutomationLogsStatus = RequestStatus.SUBSCRIBED;
            state.clientAutomationLogs = payload;
        },
        subscribeToClientLogsFailed(state, _action) {
            state.clientAutomationLogsStatus = RequestStatus.FAILED;
        },
        unsubscribeFromClientLogs(state) {
            state.clientAutomationLogs = [];
            state.selectedClient = null;
            state.clientAutomationLogsStatus = null;
        },
    },
});
export const { subscribeToClientLogsStarted, subscribeToClientLogsSuccess, subscribeToClientLogsFailed, unsubscribeFromClientLogs, } = reducerSlice.actions;
export default reducerSlice.reducer;
