import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { ContentType, } from '../../../../models/documents/ProductContentDocument';
import dayjs from 'dayjs';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const addContentModule = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/addContentModule`, async ({ productId, module }, { extra: { firestore, analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;
    const user = auth().currentUser;
    const moduleId = uuid();
    if (!user) {
        throw new Error('invalid-user');
    }
    const contentModule = {
        ...module,
        id: moduleId,
        lessons: [],
        type: ContentType.Learning,
        createdAt: dayjs().unix(),
        updatedAt: dayjs().unix(),
    };
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
    if (!data) {
        const productContent = {
            updatedAt: new Date(),
            createdAt: new Date(),
            modules: [contentModule],
            productId,
            ownerId: user.uid,
            videoCollectionId: null,
        };
        await ref.set(productContent, { merge: true });
    }
    else {
        await ref.update({ modules: firestore.FieldValue.arrayUnion(contentModule) });
    }
    analytics.track('add_content_module', { ownerId: user.uid, productId });
    return moduleId;
});
