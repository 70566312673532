import { createSlice } from '@reduxjs/toolkit';
import { SUBSCRIPTION_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    data: null,
    subscriptionStatus: null,
};
const subscriptionSlice = createSlice({
    name: SUBSCRIPTION_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToSubscriptionStarted(state) {
            if (!state.data) {
                state.subscriptionStatus = RequestStatus.FETCHING;
            }
        },
        subscribeToSubscriptionSuccess(state, { payload }) {
            state.data = payload;
            state.subscriptionStatus = RequestStatus.SUBSCRIBED;
        },
        subscribeToSubscriptionFailed(state) {
            state.subscriptionStatus = RequestStatus.FAILED;
        },
        unsubscribeFromSubscription(state) {
            state.subscriptionStatus = null;
            state.data = null;
        },
    },
});
export const { subscribeToSubscriptionFailed, subscribeToSubscriptionStarted, subscribeToSubscriptionSuccess, unsubscribeFromSubscription, } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
