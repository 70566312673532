import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const addFreeAccessInvitation = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/addFreeAccessInvitation`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS)
            .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_FREE_ACCESS);
        const res = await ref.add({
            ...payload,
            createdAt: new Date(),
            updatedAt: new Date(),
        });
        onSuccess?.();
        return res.id;
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
