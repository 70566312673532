import { createSlice } from '@reduxjs/toolkit';
import { PRODUCT_ORDERS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../..';
const initialState = {
    orders: [],
    ordersStatus: null,
    productId: null,
    searchTerm: null,
    selectedMonth: null,
    error: null,
};
const productOrdersSlice = createSlice({
    name: PRODUCT_ORDERS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductOrdersStarted(state, { payload }) {
            state.ordersStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
            state.productId = payload.productId;
            state.selectedMonth = payload.selectedMonth;
        },
        subscribeToProductOrdersSuccess(state, { payload }) {
            state.ordersStatus = RequestStatus.SUBSCRIBED;
            state.orders = payload;
        },
        subscribeToProductOrdersFailed(state, { payload }) {
            state.ordersStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromProductOrders() {
            return initialState;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
});
export const { subscribeToProductOrdersFailed, subscribeToProductOrdersStarted, subscribeToProductOrdersSuccess, unsubscribeFromProductOrders, clearSearchMode, setSearchTerm, } = productOrdersSlice.actions;
export default productOrdersSlice.reducer;
