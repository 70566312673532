import { createSlice } from '@reduxjs/toolkit';
import { COMMUNITY_REDUCER_NAME } from './types';
import { RequestStatus } from '../../..';
const initialState = {
    requestStatus: null,
    error: null,
    community: null,
    communityId: null,
};
const communitiesSlice = createSlice({
    name: COMMUNITY_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToCommunityStarted(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
            state.communityId = payload || null;
        },
        subscribeToCommunitySuccess(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBED;
            state.community = payload;
        },
        subscribeToCommunityFailed(state, { payload }) {
            state.requestStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromCommunity() {
            return initialState;
        },
    },
});
export const { subscribeToCommunityFailed, subscribeToCommunitySuccess, subscribeToCommunityStarted, unsubscribeFromCommunity, } = communitiesSlice.actions;
export default communitiesSlice.reducer;
