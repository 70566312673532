import { createAsyncThunk } from '@reduxjs/toolkit';
import { ARCHIVE_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const toggleVisibility = createAsyncThunk(`${ARCHIVE_REDUCER_NAME}/toggleVisibility`, async ({ productId, archivedEmailId, ...rest }, { extra: { db, auth, analytics } }) => {
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    const ref = db
        .collection(COLLECTION.PRODUCTS_ARCHIVE)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_ARCHIVE_EMAILS)
        .doc(archivedEmailId);
    await ref.update({
        ...rest,
        updatedAt: new Date(),
    });
    if ('status' in rest) {
        await analytics.track('toggle_archive_email_visibility', {
            productId,
            userId: user.uid,
            status: rest.status,
        });
    }
    else {
        await analytics.track('change_archive_email_visibility_on_landing', {
            productId,
            userId: user.uid,
            visibility: rest.landingPageVisibility,
        });
    }
    return {
        id: archivedEmailId,
        ...rest,
    };
});
