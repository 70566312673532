import { COLLECTION } from '../../../../firestore/collectionNames';
export const setUserStatusManually = async (params) => {
    if (!params.userId) {
        return;
    }
    if ('database' in params && params.database) {
        return params.database().ref(`/status/${params.userId}`).set({
            state: params.state,
            lastChanged: params.database.ServerValue.TIMESTAMP,
        });
    }
    else if ('firestore' in params) {
        return params.firestore().collection(COLLECTION.USERS_PRESENCE).doc(params.userId).set({
            state: params.state,
            lastChanged: params.firestore.FieldValue.serverTimestamp(),
        });
    }
};
