import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { subscribeToProductContentStarted, subscribeToProductContentFailed, subscribeToProductContentSuccess, } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const subscribeToProductContent = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/subscribeToProductContent`, async ({ productId, ownerId }, { dispatch, extra: { db, firestoreSubscriptions, auth } }) => {
    try {
        dispatch(subscribeToProductContentStarted());
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        firestoreSubscriptions.productContentListener?.();
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CONTENT)
            .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
        firestoreSubscriptions.productContentListener = ref.onSnapshot((snapshot) => {
            dispatch(subscribeToProductContentSuccess(snapshot.exists ? firestoreDateMapper(snapshot) : null));
        }, () => {
            dispatch(subscribeToProductContentFailed());
        });
    }
    catch {
        dispatch(subscribeToProductContentFailed());
    }
});
