import React, { FunctionComponent } from 'react';
import * as styles from './CheckoutWrapper.module.scss';

interface OwnProps {
  productCard: React.ReactElement;
  children: React.ReactElement[];
}

type Props = OwnProps;

const CheckoutWrapper: FunctionComponent<Props> = ({ productCard, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.productItem}>{productCard}</div>
      <div className={styles.formItem}>{children}</div>
    </div>
  );
};

export default CheckoutWrapper;
