import { SubscriberIntegrationClient } from '../../enums/SubscriberIntegrationClient';
export var PlatformThemeColor;
(function (PlatformThemeColor) {
    PlatformThemeColor["Tomato"] = "tomato";
    PlatformThemeColor["Red"] = "red";
    PlatformThemeColor["Ruby"] = "ruby";
    PlatformThemeColor["Crimson"] = "crimson";
    PlatformThemeColor["Pink"] = "pink";
    PlatformThemeColor["Plum"] = "plum";
    PlatformThemeColor["Purple"] = "purple";
    PlatformThemeColor["Violet"] = "violet";
    PlatformThemeColor["Iris"] = "iris";
    PlatformThemeColor["Indigo"] = "indigo";
    PlatformThemeColor["Blue"] = "blue";
    PlatformThemeColor["Cyan"] = "cyan";
    PlatformThemeColor["Teal"] = "teal";
    PlatformThemeColor["Jade"] = "jade";
    PlatformThemeColor["Green"] = "green";
    PlatformThemeColor["Grass"] = "grass";
    PlatformThemeColor["Brown"] = "brown";
    PlatformThemeColor["Orange"] = "orange";
    PlatformThemeColor["Sky"] = "sky";
    PlatformThemeColor["Mint"] = "mint";
    PlatformThemeColor["Lime"] = "lime";
    PlatformThemeColor["Yellow"] = "yellow";
    PlatformThemeColor["Amber"] = "amber";
    PlatformThemeColor["Gold"] = "gold";
    PlatformThemeColor["Bronze"] = "bronze";
    PlatformThemeColor["Gray"] = "gray";
})(PlatformThemeColor || (PlatformThemeColor = {}));
export const PLATFORM_COLOR_MAP = {
    [PlatformThemeColor.Tomato]: '#e54d2e',
    [PlatformThemeColor.Red]: '#e5484d',
    [PlatformThemeColor.Ruby]: '#e54666',
    [PlatformThemeColor.Crimson]: '#e93d82',
    [PlatformThemeColor.Pink]: '#d6409f',
    [PlatformThemeColor.Orange]: '#f76b15',
    [PlatformThemeColor.Lime]: '#bdee63',
    [PlatformThemeColor.Yellow]: '#ffe629',
    [PlatformThemeColor.Amber]: '#ffc53d',
    [PlatformThemeColor.Plum]: '#ab4aba',
    [PlatformThemeColor.Purple]: '#8e4ec6',
    [PlatformThemeColor.Violet]: '#6e56cf',
    [PlatformThemeColor.Iris]: '#5b5bd6',
    [PlatformThemeColor.Indigo]: '#3e63dd',
    [PlatformThemeColor.Blue]: '#0090ff',
    [PlatformThemeColor.Cyan]: '#00a2c7',
    [PlatformThemeColor.Sky]: '#7ce2fe',
    [PlatformThemeColor.Mint]: '#86ead4',
    [PlatformThemeColor.Teal]: '#12a594',
    [PlatformThemeColor.Jade]: '#29a383',
    [PlatformThemeColor.Green]: '#30a46c',
    [PlatformThemeColor.Grass]: '#46a758',
    [PlatformThemeColor.Brown]: '#ad7f58',
    [PlatformThemeColor.Bronze]: '#a18072',
    [PlatformThemeColor.Gold]: '#978365',
    [PlatformThemeColor.Gray]: '#8d8d8d',
};
export var PlatformRadius;
(function (PlatformRadius) {
    PlatformRadius["None"] = "none";
    PlatformRadius["Small"] = "small";
    PlatformRadius["Medium"] = "medium";
    PlatformRadius["Large"] = "large";
    PlatformRadius["Full"] = "full";
})(PlatformRadius || (PlatformRadius = {}));
export const PlatformRadiusValues = {
    [PlatformRadius.None]: 0,
    [PlatformRadius.Small]: 3,
    [PlatformRadius.Medium]: 4,
    [PlatformRadius.Large]: 6,
    [PlatformRadius.Full]: 9999,
};
export var PlatformGrayScale;
(function (PlatformGrayScale) {
    PlatformGrayScale["Auto"] = "auto";
    PlatformGrayScale["Slate"] = "slate";
    PlatformGrayScale["Gray"] = "gray";
    PlatformGrayScale["Mauve"] = "mauve";
    PlatformGrayScale["Sage"] = "sage";
    PlatformGrayScale["Sand"] = "sand";
    PlatformGrayScale["Olive"] = "olive";
})(PlatformGrayScale || (PlatformGrayScale = {}));
export var PlatformStyleVariant;
(function (PlatformStyleVariant) {
    PlatformStyleVariant["Solid"] = "solid";
    PlatformStyleVariant["Classic"] = "classic";
})(PlatformStyleVariant || (PlatformStyleVariant = {}));
export var MainPlatformPage;
(function (MainPlatformPage) {
    MainPlatformPage["Sales"] = "/";
    MainPlatformPage["Dashboard"] = "/dashboard";
})(MainPlatformPage || (MainPlatformPage = {}));
export var ProductsListVariant;
(function (ProductsListVariant) {
    ProductsListVariant["Grid"] = "grid";
    ProductsListVariant["Horizontal"] = "horizontal";
})(ProductsListVariant || (ProductsListVariant = {}));
