import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const updateProductUserSettingsConfig = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateProductUSerSettingsConfig`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { analytics, auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const collectionRef = db
            .collection(COLLECTION.USERS)
            .doc(payload.ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_CONFIG);
        const doc = await collectionRef
            .doc(`${FIRESTORE_DOCUMENT.PRODUCT_USER_SETTINGS_PREFIX}${user.uid}`)
            .get();
        if (doc.exists) {
            await collectionRef
                .doc(`${FIRESTORE_DOCUMENT.PRODUCT_USER_SETTINGS_PREFIX}${user.uid}`)
                .update({ showActivePrices: payload.showActivePrices, updatedAt: new Date() });
        }
        analytics.track('update_product_user_settings_config', {
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
