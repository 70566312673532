import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { DISCOUNT_REDUCER_NAME } from '../types';
export const fetchDiscounts = createAsyncThunk(`${DISCOUNT_REDUCER_NAME}/fetchDiscounts`, async ({ ownerId }, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.DISCOUNTS)
            .where('status', '==', 'active')
            .orderBy('createdAt', 'desc');
        const res = await ref.get();
        const list = res.docs.map((doc) => firestoreDateMapper(doc));
        return {
            list,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
