import { logInFailed } from '../reducer';
import { setUserStatusManually } from '../userPresence/setUserStatusManually';
import { setUserStatus } from '../userPresence/setUserStatusInRealtimeDatabase';
export const setUserPresence = (state) => async (dispatch, getState, { database, firestore }) => {
    try {
        const { user } = getState();
        if (user?.data) {
            const { uid: userId } = user.data;
            if (!userId) {
                return;
            }
            if (state) {
                await setUserStatusManually({ database, userId, state, firestore });
                return;
            }
            if (database) {
                await setUserStatus(database, userId);
            }
        }
    }
    catch (e) {
        dispatch(logInFailed(e.code));
    }
};
