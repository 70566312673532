export var EmailClient;
(function (EmailClient) {
    EmailClient["ActiveCampaign"] = "ActiveCampaign";
    EmailClient["MailChimp"] = "MailChimp";
    EmailClient["ConvertKit"] = "ConvertKit";
    EmailClient["MailerLite"] = "MailerLite";
    EmailClient["GetResponse"] = "GetResponse";
    EmailClient["FreshMail"] = "FreshMail";
    EmailClient["Pipedrive"] = "Pipedrive";
})(EmailClient || (EmailClient = {}));
