import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../..';
export const fetchFreeAccessInvitation = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchFreeAccessInvitation`, async ({ productId, email }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const { freeAccessListSize } = getState().product;
        let ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS)
            .doc(FIRESTORE_DOCUMENT.PRODUCTS_DATA)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_FREE_ACCESS)
            .orderBy('createdAt', 'desc')
            .limit(freeAccessListSize);
        if (email) {
            ref = ref.where('email', '==', email);
        }
        const res = await ref.get();
        const list = res.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        if (!res.empty) {
            firestoreSubscriptions.lastFreeAccessInvitationCursor = res.docs[res.docs.length - 1];
        }
        return {
            list,
            isLastPage: list.length < freeAccessListSize,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
