import { createSlice } from '@reduxjs/toolkit';
import { NEWSLETTERS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    list: [],
    listStatus: null,
    listError: null,
    searchTerm: null,
};
const newslettersSlice = createSlice({
    name: NEWSLETTERS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToNewslettersStarted(state) {
            state.listStatus = RequestStatus.SUBSCRIBING;
            state.listError = null;
        },
        subscribeToNewslettersSuccess(state, { payload }) {
            state.listStatus = RequestStatus.SUBSCRIBED;
            state.list = payload;
        },
        subscribeToNewslettersFailed(state, { payload }) {
            state.listStatus = RequestStatus.FAILED;
            state.listError = payload;
        },
        unsubscribeFromNewsletters(state) {
            state.list = [];
            state.listStatus = null;
            state.listError = null;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
});
export const { subscribeToNewslettersFailed, subscribeToNewslettersStarted, subscribeToNewslettersSuccess, unsubscribeFromNewsletters, clearSearchMode, setSearchTerm, } = newslettersSlice.actions;
export default newslettersSlice.reducer;
