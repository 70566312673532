import { createSlice } from '@reduxjs/toolkit';
import { COMMUNITIES_REDUCER_NAME } from './types';
import { createCommunity } from './actions';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    adding: false,
    error: null,
    data: [],
    requestStatus: null,
    searchTerm: null,
};
const communitiesSlice = createSlice({
    name: COMMUNITIES_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToCommunitiesStarted(state) {
            state.requestStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
        },
        subscribeToCommunitiesSuccess(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToCommunitiesFailed(state, { payload }) {
            state.requestStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromCommunities(state) {
            state.data = [];
            state.error = null;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createCommunity.pending, (state) => {
            state.adding = true;
        });
        builder.addCase(createCommunity.fulfilled, (state) => {
            state.adding = false;
        });
        builder.addCase(createCommunity.rejected, (state) => {
            state.adding = false;
        });
    },
});
export const { subscribeToCommunitiesStarted, subscribeToCommunitiesFailed, subscribeToCommunitiesSuccess, unsubscribeFromCommunities, clearSearchMode, setSearchTerm, } = communitiesSlice.actions;
export default communitiesSlice.reducer;
