export var DiscountStatus;
(function (DiscountStatus) {
    DiscountStatus["Active"] = "active";
    DiscountStatus["Suspended"] = "suspended";
    DiscountStatus["Deactivated"] = "deactivated";
    DiscountStatus["Archive"] = "archive";
})(DiscountStatus || (DiscountStatus = {}));
export var DiscountType;
(function (DiscountType) {
    DiscountType["Percent"] = "percent";
    DiscountType["Number"] = "number";
})(DiscountType || (DiscountType = {}));
