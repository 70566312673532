import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTERS_REDUCER_NAME } from '../../newsletters/types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const updateInvoiceData = createAsyncThunk(`${NEWSLETTERS_REDUCER_NAME}/updateInvoiceData`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        if (payload.updateClientData !== false) {
            const ref = db
                .collection(COLLECTION.USERS)
                .doc(payload.authorId)
                .collection(COLLECTION.PRODUCTS_LIST)
                .doc(payload.productId)
                .collection(COLLECTION.PRODUCT_CLIENTS)
                .doc(payload.clientEmail || user.email);
            await ref.withConverter(getDocumentConverter()).update({
                client: payload.invoiceData,
            });
        }
        if (payload.orderId) {
            const ref = db
                .collection(COLLECTION.USERS)
                .doc(payload.authorId)
                .collection(COLLECTION.PRODUCTS_LIST)
                .doc(payload.productId)
                .collection(COLLECTION.PRODUCT_ORDERS)
                .doc(payload.orderId);
            const orderToUpdate = {
                client: payload.invoiceData,
            };
            if (payload.issueDate) {
                orderToUpdate.issueDate = payload.issueDate.unix();
            }
            if (payload.sellDate) {
                orderToUpdate.sellDate = payload.sellDate.unix();
            }
            await ref.withConverter(getDocumentConverter()).update(orderToUpdate);
        }
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
