import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCTS_REDUCER_NAME } from '../types';
import { cloudFunctionErrorHandler } from '../../../../helpers/cloudFunctionErrorHandler';
export const getNFTCollection = createAsyncThunk(`${PRODUCTS_REDUCER_NAME}/addProduct`, async (dto, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        const res = await functions.httpsCallable('tokens-getNFTCollection')(dto);
        analytics.track('get_nft_collection', {
            collectionAddress: dto.tokenAddress,
            chain: dto.chain,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        return res.data?.body.collection;
    }
    catch (e) {
        return rejectWithValue(cloudFunctionErrorHandler(e).code);
    }
});
