import uniqBy from 'lodash.uniqby';
import { createSelector } from '@reduxjs/toolkit';
const getIntegrations = (store) => store.integration.data;
export const getTagsOptions = createSelector([getIntegrations], (integration) => {
    if (!integration || !integration.mailers) {
        return [];
    }
    return uniqBy(Object.values(integration.mailers)
        .flat()
        .map((account) => {
        return account?.tags.map((tagObj) => ({
            value: tagObj.tag,
            label: tagObj.tag,
        }));
    })
        .filter((item) => !!item?.length)
        .flat(), 'value');
});
