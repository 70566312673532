import { InvoicesClient } from '../../enums/InvoicesClient';
import { getSizeByGigabytes } from '../../helpers/getSizeByBytes';
export var SubscriptionTier;
(function (SubscriptionTier) {
    SubscriptionTier["Free"] = "10/100/1/50/10";
    SubscriptionTier["Basic"] = "100/1000/5/500/100";
    SubscriptionTier["Standard"] = "500/5000/25/5000/200";
    SubscriptionTier["Professional"] = "10000/25000/100/25000/750";
})(SubscriptionTier || (SubscriptionTier = {}));
export const getTierLimits = (tier) => {
    if (!tier) {
        return getTierLimits(SubscriptionTier.Free);
    }
    const [paidClients, freeNewsletterContacts, products, monthlyOnetimeTransactions, uploadLimit] = tier.split('/').map((value) => Number(value));
    return {
        paidClients,
        freeNewsletterContacts,
        products,
        monthlyOnetimeTransactions,
        uploadLimit: getSizeByGigabytes(uploadLimit),
    };
};
