import { createSlice } from '@reduxjs/toolkit';
import { PRODUCTS_REDUCER_NAME } from './types';
import { addProduct } from './actions';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    productsInfo: null,
    productsInfoStatus: null,
    productsListStatus: null,
    productsListData: [],
    addingNewProduct: false,
    productsError: null,
    searchTerm: null,
};
const productsSlice = createSlice({
    name: PRODUCTS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductsStarted(state) {
            state.productsListStatus = RequestStatus.SUBSCRIBING;
            state.productsError = null;
        },
        subscribeToProductsSuccess(state, { payload }) {
            state.productsListStatus = RequestStatus.SUBSCRIBED;
            state.productsListData = payload;
        },
        subscribeToProductsFailed(state, { payload }) {
            state.productsListStatus = RequestStatus.FAILED;
            state.productsError = payload;
        },
        unsubscribeFromProducts(state) {
            state.productsInfoStatus = null;
            state.productsListData = [];
            state.productsError = null;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addProduct.pending, (state) => {
            state.addingNewProduct = true;
        });
        builder.addCase(addProduct.fulfilled, (state) => {
            state.addingNewProduct = false;
        });
        builder.addCase(addProduct.rejected, (state) => {
            state.addingNewProduct = false;
        });
    },
});
export const { subscribeToProductsStarted, subscribeToProductsFailed, subscribeToProductsSuccess, unsubscribeFromProducts, clearSearchMode, setSearchTerm, } = productsSlice.actions;
export default productsSlice.reducer;
