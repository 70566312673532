import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTER_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateNewsletterEndAccessStrategy = createAsyncThunk(`${NEWSLETTER_REDUCER_NAME}/updateNewsletterEndAccessStrategy`, async (payload, { extra: { db, auth } }) => {
    const user = auth().currentUser;
    if (!user || !user.email) {
        throw new Error('user-is-not-logged');
    }
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(payload.productId)
        .collection(COLLECTION.PRODUCT_CLIENTS)
        .doc(payload.clientEmail);
    await ref.update({
        'accessPeriod.endAccessStrategy': payload.updatedAccessStrategy,
    });
});
