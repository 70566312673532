import React, { FunctionComponent, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import * as styles from './Checkout.module.scss';
import { Button, Card, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useQuery } from '~/hooks/useQuery';

interface OwnProps {}

type Props = OwnProps;

const CheckoutSuccess: FunctionComponent<Props> = () => {
  const { t } = useTranslation('checkout');
  const queryParams = useQuery();
  const productType = queryParams.get('type') || 'service';
  const isRenewal = queryParams.get('renewal') === 'true';
  const failed = queryParams.get('redirect_status') === 'failed';
  const history = useHistory();

  useEffect(() => {
    if (failed) {
      history.push(
        `/checkout/failure?priceId=${queryParams.get('priceId')}&productId=${queryParams.get(
          'productId'
        )}}`
      );
    }

    const email = queryParams.get('email')?.replace(' ', '+');

    if (email) {
      window.localStorage.setItem('emailForSignIn', email);
    }

    firebase.analytics().logEvent('product_purchased', {
      email,
      productName: queryParams.get('name'),
      productId: queryParams.get('productId'),
    });
  }, [failed, history, queryParams]);

  if (isRenewal) {
    return (
      <div className={styles.background}>
        <Card
          className={styles.infoCard}
          title={t<string>('checkout:renewalTitle', {
            name: queryParams.get('name') ?? '',
          })}
        >
          <Result
            status="success"
            style={{ minWidth: 300 }}
            title={t<string>('success.renewalTitle')}
            subTitle={<p className={styles.emptyLineBefore}>{t<string>('success.renewalDesc')}</p>}
            extra={
              <Button type="primary" onClick={() => history.push('/newsletters')}>
                {t<string>('success.goToApp')}
              </Button>
            }
          />
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.background}>
      <Card
        className={styles.infoCard}
        title={t<string>('checkout:title', { name: queryParams.get('name') ?? '' })}
      >
        <Result
          status="success"
          title={t<string>('success.title')}
          subTitle={
            <p className={styles.emptyLineBefore}>
              {productType === 'service'
                ? t<string>('success.desc')
                : t<string>('success.goodDesc')}
            </p>
          }
          extra={<p className={styles.subtitle}>{t<string>('success.explanation')}</p>}
        />
      </Card>
    </div>
  );
};

export default CheckoutSuccess;
