export var ContentType;
(function (ContentType) {
    ContentType["Learning"] = "learning";
})(ContentType || (ContentType = {}));
export var ModuleStatus;
(function (ModuleStatus) {
    ModuleStatus["Draft"] = "draft";
    ModuleStatus["Public"] = "public";
    ModuleStatus["Hidden"] = "hidden";
    ModuleStatus["Locked"] = "locked";
})(ModuleStatus || (ModuleStatus = {}));
export var LessonStatus;
(function (LessonStatus) {
    LessonStatus["Draft"] = "draft";
    LessonStatus["Public"] = "public";
    LessonStatus["Hidden"] = "hidden";
    LessonStatus["Locked"] = "locked";
})(LessonStatus || (LessonStatus = {}));
export var LessonType;
(function (LessonType) {
    LessonType["Video"] = "video";
    LessonType["ExternalVideo"] = "externalVideo";
    LessonType["Article"] = "article";
    LessonType["Task"] = "task";
})(LessonType || (LessonType = {}));
export var VideoLessonStatus;
(function (VideoLessonStatus) {
    VideoLessonStatus["Pending"] = "Pending";
    VideoLessonStatus["Queued"] = "queued";
    VideoLessonStatus["Processing"] = "processing";
    VideoLessonStatus["Encoding"] = "encoding";
    VideoLessonStatus["Ready"] = "ready";
    VideoLessonStatus["Failed"] = "failed";
})(VideoLessonStatus || (VideoLessonStatus = {}));
