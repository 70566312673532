import { createSlice } from '@reduxjs/toolkit';
import { REFERRALS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    referralCode: null,
    status: null,
    statistics: null,
};
const reducerSlice = createSlice({
    initialState,
    name: REFERRALS_REDUCER_NAME,
    reducers: {
        subscribeToUserReferralsStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
        },
        subscribeToUserReferralsSuccess(state, { payload }) {
            state.status = RequestStatus.SUBSCRIBED;
            state.referralCode = payload.referralCode;
            state.statistics = payload.statistics;
        },
        subscribeToUserReferralsFailed(state, _action) {
            state.status = RequestStatus.FAILED;
        },
        unsubscribeFromUserReferrals(state) {
            state.status = null;
            state.referralCode = null;
            state.statistics = null;
        },
    },
});
export const { subscribeToUserReferralsStarted, subscribeToUserReferralsSuccess, subscribeToUserReferralsFailed, unsubscribeFromUserReferrals, } = reducerSlice.actions;
export default reducerSlice.reducer;
