import { createAsyncThunk } from '@reduxjs/toolkit';
import { SUBSCRIPTION_REDUCER_NAME } from '../types';
export const changeSubscriptionPlan = createAsyncThunk(`${SUBSCRIPTION_REDUCER_NAME}/changeSubscriptionPlan`, async (payload, { rejectWithValue, extra: { functions } }) => {
    try {
        await functions.httpsCallable('subscriptions-changeSubscriptionPlan')(payload);
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
