import { createSelector } from '@reduxjs/toolkit';
const getResources = (store) => store.productResources.data;
const getSearchTerm = (store) => store.productResources.searchTerm;
export const getFilteredResources = createSelector([getResources, getSearchTerm], (resources, searchTerm) => {
    if (!searchTerm || !searchTerm.length) {
        return resources;
    }
    const searchInLowerCase = searchTerm.toLowerCase();
    return resources.filter((resources) => {
        if (resources.title.toLowerCase().includes(searchInLowerCase)) {
            return true;
        }
        return false;
    });
});
