export var ProductResourceStatus;
(function (ProductResourceStatus) {
    ProductResourceStatus["Draft"] = "draft";
    ProductResourceStatus["Available"] = "available";
    ProductResourceStatus["Unavailable"] = "unavailable";
})(ProductResourceStatus || (ProductResourceStatus = {}));
export var ProductResourceNotificationStatus;
(function (ProductResourceNotificationStatus) {
    ProductResourceNotificationStatus["Requested"] = "requested";
    ProductResourceNotificationStatus["Processing"] = "processing";
    ProductResourceNotificationStatus["Sent"] = "sent";
    ProductResourceNotificationStatus["Failed"] = "failed";
})(ProductResourceNotificationStatus || (ProductResourceNotificationStatus = {}));
export var ProductResourceType;
(function (ProductResourceType) {
    ProductResourceType["InApp"] = "inApp";
    ProductResourceType["Notion"] = "Notion";
})(ProductResourceType || (ProductResourceType = {}));
