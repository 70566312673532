import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
export const integrateWithInvoicesClient = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/integrateWithInvoicesClient`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-integrateWithInvoicesClient')(payload);
        analytics.track('integrate_with_invoices_client', {
            userId: user.uid,
            email: user.email,
            client: payload.client,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
