export class Size {
    _bytes;
    constructor(bytes) {
        this._bytes = bytes;
    }
    get bytes() {
        return this._bytes;
    }
    get gigabytes() {
        return this._bytes / (1024 * 1024 * 1024);
    }
}
export const getSizeByBytes = (bytes) => {
    return new Size(bytes);
};
export const getSizeByGigabytes = (gigabytes) => {
    return new Size(gigabytes * 1024 * 1024 * 1024);
};
