import common from './common.json';
import auth from './auth.json';
import dashboard from './dashboard.json';
import profile from './userProfile.json';
import products from './products.json';
import checkout from './checkout.json';
import newsletters from './newsletters.json';
import chat from './chat.json';
import settings from './settings.json';
import subscription from './subscription.json';
import content from './content.json';
import communities from './communities.json';
export default {
    common,
    auth,
    dashboard,
    profile,
    products,
    checkout,
    chat,
    settings,
    newsletters,
    subscription,
    content,
    communities,
};
