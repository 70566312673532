import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REFERRALS_REDUCER_NAME } from '../types';
export const handleRewardConfirmation = createAsyncThunk(`${PRODUCT_REFERRALS_REDUCER_NAME}/handleRewardConfirmation`, async (payload, { rejectWithValue, extra: { functions }, getState }) => {
    try {
        const { data } = getState().productReferrals;
        if (!data) {
            throw new Error('product referrals data not exists');
        }
        await functions.httpsCallable('referrals-handleRewardConfirmation')({
            productId: data.productId,
            ...payload,
        });
    }
    catch (e) {
        return rejectWithValue();
    }
});
