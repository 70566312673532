import { PRODUCT_FILES_REDUCER_NAME } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    data: null,
    status: null,
    error: null,
};
const productFilesSlice = createSlice({
    name: PRODUCT_FILES_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductFilesStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
            state.data = null;
            state.error = null;
        },
        subscribeToProductFilesSuccess(state, { payload }) {
            state.status = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToProductFilesFailed(state, { payload }) {
            state.status = RequestStatus.FAILED;
            state.error = payload.message;
        },
        unsubscribeFromProductFiles(state) {
            state.status = null;
            state.data = null;
            state.error = null;
        },
    },
});
export const { subscribeToProductFilesStarted, subscribeToProductFilesSuccess, subscribeToProductFilesFailed, unsubscribeFromProductFiles, } = productFilesSlice.actions;
export default productFilesSlice.reducer;
