import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
export const removeIntegrationWithStripe = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/removeIntegrationWithStripe`, async ({ onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-removeIntegrationWithStripe')();
        analytics.track('remove_integration_with_stripe', {
            userId: user.uid,
        });
        onSuccess?.();
    }
    catch (e) {
        const error = e;
        onFailure?.(error.code === 'permission-denied' ? 403 : 500);
        return rejectWithValue(e);
    }
});
