import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { subscribeToProductContentLessonFailed, subscribeToProductContentLessonStarted, subscribeToProductContentLessonSuccess, } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
export const subscribeToProductContentLesson = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/subscribeToProductContentLesson`, async ({ productId, lessonId, ownerId }, { dispatch, extra: { db, firestoreSubscriptions, auth } }) => {
    try {
        dispatch(subscribeToProductContentLessonStarted());
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        firestoreSubscriptions.productContentLessonListener?.();
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_CONTENT)
            .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE)
            .collection(COLLECTION.PRODUCT_CONTENT_LESSONS)
            .doc(lessonId);
        firestoreSubscriptions.productContentLessonListener = ref.onSnapshot((snapshot) => {
            dispatch(subscribeToProductContentLessonSuccess(snapshot.exists ? firestoreDateMapper(snapshot) : null));
        }, () => {
            dispatch(subscribeToProductContentLessonFailed());
        });
    }
    catch {
        dispatch(subscribeToProductContentLessonFailed());
    }
});
