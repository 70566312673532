import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const fetchAllProductCourseProgress = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchAllProductCourseProgress`, async ({ productId }, { extra: { db } }) => {
    const ref = db
        .collection(COLLECTION.PRODUCT_COURSE_PROGRESS)
        .where('productId', '==', productId)
        .orderBy('numberOfSessions', 'desc')
        .withConverter(getDocumentConverter());
    const snapshot = await ref.get();
    const list = snapshot.docs
        .map((doc) => doc.data())
        .filter((data) => typeof data.numberOfSessions === 'number');
    return { list };
});
