import { createAsyncThunk } from '@reduxjs/toolkit';
import { ADMIN_REDUCER_NAME } from '../types';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
import { FIRESTORE_COLLECTION } from '../../../../index';
export const fetchProductsOwners = createAsyncThunk(`${ADMIN_REDUCER_NAME}/fetchProductsOwners`, async (_, { extra: { db } }) => {
    const ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .orderBy('totalNumberOfTransactions', 'desc')
        .withConverter(getDocumentConverter());
    const data = await ref.get();
    return data.docs.map((doc) => doc.data());
});
