import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { SubscriberIntegrationClient } from '../../../../enums/SubscriberIntegrationClient';
export const removeClientDiscordIntegration = createAsyncThunk(`${USER_REDUCER}/removeClientDiscordIntegration`, async (_, { rejectWithValue, extra: { firestore, auth } }) => {
    try {
        const user = await auth().currentUser;
        if (!user || !user.email) {
            throw new Error('User is not logged');
        }
        await firestore()
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .update({
            [`clientIntegrations.${SubscriberIntegrationClient.Discord}`]: firestore.FieldValue?.delete(),
        });
    }
    catch (e) {
        if (e instanceof Error) {
            throw rejectWithValue(e.message);
        }
        throw rejectWithValue('Unknown error');
    }
});
