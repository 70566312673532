import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
import { cloudFunctionErrorHandler } from '../../../../helpers/cloudFunctionErrorHandler';
export const verifyDiscordIntegration = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/verifyDiscordIntegration`, async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-checkDiscordBotIntegrationWithGuild')(payload);
        analytics.track('integrated_with_discord', {
            userId: user.uid,
            email: user.email,
        });
    }
    catch (e) {
        return rejectWithValue(cloudFunctionErrorHandler(e).code);
    }
});
