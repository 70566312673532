import { createAsyncThunk } from '@reduxjs/toolkit';
import { STATISTICS_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchCreatorStats = createAsyncThunk(`${STATISTICS_REDUCER_NAME}/fetchCreatorStats`, async (_, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db.collection(COLLECTION.REPORTS).doc(user.uid);
        const doc = await ref.get();
        return doc.exists ? firestoreDateMapper(doc) : null;
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
