import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
export const updateLessonVideo = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/updateLessonVideo`, async (dto, { extra: { analytics, auth, functions } }) => {
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    await functions.httpsCallable('videos-updateVideoForLesson')(dto);
    analytics.track('update_video_in_lesson', {
        ownerId: user.uid,
        productId: dto.productId,
    });
});
