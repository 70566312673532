import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMUNITIES_REDUCER_NAME } from '../types';
export const deleteCommunity = createAsyncThunk(`${COMMUNITIES_REDUCER_NAME}/deleteCommunity`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        await functions.httpsCallable('communities-deleteCommunity')({
            communityId: payload,
        });
        analytics.track('delete_community', {
            communityId: payload,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
