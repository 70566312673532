import { createAsyncThunk } from '@reduxjs/toolkit';
import { ADMIN_REDUCER_NAME } from '../types';
import { validate } from 'uuid';
import { FIRESTORE_COLLECTION } from '../../../../index';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const searchProductOrOwner = createAsyncThunk(`${ADMIN_REDUCER_NAME}/searchProductOrOwner`, async ({ searchTerm, isEmail }, { extra: { db } }) => {
    let ref = db
        .collection(FIRESTORE_COLLECTION.REPORTS)
        .where('creatorId', '>=', searchTerm)
        .where('creatorId', '<=', searchTerm + '~')
        .withConverter(getDocumentConverter());
    if (isEmail || !validate(searchTerm)) {
        ref = db
            .collection(FIRESTORE_COLLECTION.REPORTS)
            .where('creatorEmail', '>=', searchTerm)
            .where('creatorEmail', '<=', searchTerm + '~')
            .withConverter(getDocumentConverter());
    }
    if (searchTerm.startsWith('prod_')) {
        const productRef = db
            .collectionGroup(FIRESTORE_COLLECTION.PRODUCTS_LIST)
            .where('id', '==', searchTerm)
            .orderBy('createdAt', 'desc')
            .withConverter(getDocumentConverter());
        const snapshot = await productRef.get();
        const [doc] = snapshot.docs;
        const [_, ownerId] = doc.ref.path.split('/');
        ref = db
            .collection(FIRESTORE_COLLECTION.REPORTS)
            .where('creatorId', '>=', ownerId)
            .where('creatorId', '<=', ownerId + '~')
            .withConverter(getDocumentConverter());
    }
    const data = await ref.get();
    return data.docs.map((doc) => doc.data());
});
