import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchProductClientImports = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchProductClientImports`, async ({ productId, ownerId, importId }, { rejectWithValue, extra: { auth, db, firestoreSubscriptions }, getState }) => {
    try {
        const { productClientImportsListSize } = getState().product;
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_IMPORTS)
            .where('importId', '==', importId)
            .orderBy('createdAt', 'desc')
            .limit(productClientImportsListSize);
        const res = await ref.get();
        const list = res.docs.map((doc) => firestoreDateMapper(doc));
        const totalRef = db
            .collection(COLLECTION.USERS)
            .doc(ownerId || user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_IMPORT_AGGREGATIONS)
            .doc(importId);
        const totalRes = await totalRef.get();
        const totalImportsSize = totalRes.exists
            ? totalRes.data()?.total
            : list.length;
        if (!res.empty) {
            firestoreSubscriptions.lastProductClientImportsCursor = res.docs[res.docs.length - 1];
        }
        return {
            list,
            isLastPage: totalImportsSize < productClientImportsListSize,
            total: totalImportsSize,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
