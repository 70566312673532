import { combineReducers } from '@reduxjs/toolkit';
import { userReducer } from './user';
import { ordersReducer } from './orders';
import { productsReducer } from './products';
import { productReducer } from './product';
import { notificationsReducer } from './notifications';
import { integrationReducer } from './integration';
import { newslettersReducer } from './newsletters';
import { newsletterReducer } from './newsletter';
import { archiveReducer } from './archive';
import { productAutomationReducer } from './productAutomation';
import { statisticsReducer } from './statistics';
import { productResourcesReducer } from './productResources';
import { productFilesReducer } from './productFiles';
import { productReferralsReducer } from './productReferrals';
import { productOrdersReducer } from './productOrders';
import { subscriptionReducer } from './subscription';
import { productContentReducer } from './productContent';
import { adminReducer } from './admin';
import { automationLogsReducer } from './automationLogs';
import { integrationApiTokensReducer } from './integrationApiTokens';
import { userReferralsReducer } from './userReferrals';
import { discountReducer } from './discounts';
import { productUserSettingsConfigReducer } from './productUserSettingsConfig';
import { communitiesReducer } from './communities';
import { communityReducer } from './community';
import { communityMembersReducer } from './communityMembers';
const rootReducer = combineReducers({
    user: userReducer,
    orders: ordersReducer,
    products: productsReducer,
    product: productReducer,
    notifications: notificationsReducer,
    integration: integrationReducer,
    integrationApiTokens: integrationApiTokensReducer,
    newsletters: newslettersReducer,
    newsletter: newsletterReducer,
    archive: archiveReducer,
    productAutomation: productAutomationReducer,
    statistics: statisticsReducer,
    productResources: productResourcesReducer,
    productFiles: productFilesReducer,
    productReferrals: productReferralsReducer,
    productOrders: productOrdersReducer,
    subscription: subscriptionReducer,
    productContent: productContentReducer,
    admin: adminReducer,
    automationLogs: automationLogsReducer,
    userReferrals: userReferralsReducer,
    discount: discountReducer,
    productUserSettings: productUserSettingsConfigReducer,
    communities: communitiesReducer,
    community: communityReducer,
    communityMembers: communityMembersReducer,
});
const LOG_OUT_ACTION = 'User/logOutSuccess';
export const createRootReducer = (state, action) => {
    let newState = state;
    if (action.type === LOG_OUT_ACTION) {
        newState = undefined;
    }
    return rootReducer(newState, action);
};
export default rootReducer;
