import { LessonType } from '../models/documents/ProductContentDocument';
export const getClientsProgress = (productContent, productCourseProgress) => {
    if (!productContent || !productCourseProgress) {
        return [];
    }
    const lessons = productContent.modules.map((module) => module.lessons).flat();
    const progress = productCourseProgress.map((progress) => {
        let firstSessionAt = progress.firstSessionAt || null;
        let lastSessionAt = progress.lastSessionAt || null;
        const status = lessons.reduce((prev, current) => {
            prev.completed =
                prev.completed +
                    (current.type === LessonType.Video &&
                        progress &&
                        progress.lessonsProgress[current.id]?.completed
                        ? current.length ?? 0
                        : 0);
            prev.totality =
                prev.totality + (current.type === LessonType.Video ? current.length ?? 0 : 0);
            prev.totalLessons = prev.totalLessons + 1;
            prev.completedLessons =
                prev.completedLessons + (progress.lessonsProgress[current.id]?.completed ? 1 : 0);
            if (progress.lessonsProgress[current.id]) {
                if (!firstSessionAt || progress.lessonsProgress[current.id].updatedAt < firstSessionAt) {
                    firstSessionAt = progress.lessonsProgress[current.id].updatedAt;
                }
                if (!lastSessionAt || progress.lessonsProgress[current.id].updatedAt > lastSessionAt) {
                    lastSessionAt = progress.lessonsProgress[current.id].updatedAt;
                }
            }
            return prev;
        }, { totality: 0, completed: 0, totalLessons: 0, completedLessons: 0 });
        return {
            ...progress,
            totalLessons: status.totalLessons,
            completedLessons: status.completedLessons,
            firstSessionAt,
            lastSessionAt,
            courseProgress: Math.ceil((status.completed / status.totality) * 100),
        };
    });
    return progress;
};
