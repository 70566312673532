import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_CONTENT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
import dayjs from 'dayjs';
export const updateContentLessonStatus = createAsyncThunk(`${PRODUCT_CONTENT_REDUCER_NAME}/updateContentLessonStatus`, async ({ productId, moduleId, newStatus, lessonId }, { extra: { analytics, auth, db }, getState }) => {
    const { data } = getState().productContent;
    const user = auth().currentUser;
    if (!user) {
        throw new Error('invalid-user');
    }
    if (!data || !data.modules.some((module) => module.id === moduleId)) {
        throw new Error('invalid-module');
    }
    const now = dayjs();
    const batch = db.batch();
    const ref = db
        .collection(COLLECTION.USERS)
        .doc(user.uid)
        .collection(COLLECTION.PRODUCTS_LIST)
        .doc(productId)
        .collection(COLLECTION.PRODUCT_CONTENT)
        .doc(FIRESTORE_DOCUMENT.PRODUCT_CONTENT_COURSE);
    const lessonOnList = data.modules
        .find((module) => module.id === moduleId)
        ?.lessons.find((l) => l.id === lessonId);
    if (lessonOnList) {
        const updated = {
            ...lessonOnList,
            status: newStatus,
            updatedAt: now.unix(),
        };
        const updatedModules = data.modules.map((module) => module.id === moduleId
            ? {
                ...module,
                lessons: module.lessons.map((l) => (l.id === lessonId ? updated : l)),
            }
            : module);
        batch.update(ref, {
            modules: updatedModules,
        });
    }
    batch.update(ref.collection(COLLECTION.PRODUCT_CONTENT_LESSONS).doc(lessonId), {
        status: newStatus,
        updatedAt: now.toDate(),
    });
    await batch.commit();
    analytics.track('updated_lesson_status', {
        ownerId: user.uid,
        productId,
        moduleId,
    });
    return lessonId;
});
