import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateProductPriceLabel = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/updateProductPriceLabel`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const { product } = getState();
        if (product.details?.id !== payload.productId) {
            throw new Error('different-product-id');
        }
        if (!product.details.prices || !product.details.prices.length) {
            throw new Error('product-without-prices');
        }
        const updatedPrices = product.details.prices.map((price) => price.id === payload.priceId ? { ...price, customLabel: payload.customLabel } : price);
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId);
        await ref.update({
            prices: updatedPrices,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
