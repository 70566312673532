export var PriceStatus;
(function (PriceStatus) {
    PriceStatus["Available"] = "available";
    PriceStatus["Archived"] = "archived";
})(PriceStatus || (PriceStatus = {}));
export var EndAccessStrategy;
(function (EndAccessStrategy) {
    EndAccessStrategy["Cancel"] = "cancel";
    EndAccessStrategy["OfferWithTheSamePrice"] = "offerWithTheSamePrice";
    EndAccessStrategy["OfferWithTheCurrentPrices"] = "offerWithTheCurrentPrices";
})(EndAccessStrategy || (EndAccessStrategy = {}));
export var AccessStrategyAfterSell;
(function (AccessStrategyAfterSell) {
    AccessStrategyAfterSell["CancelImmediately"] = "cancelImmediately";
    AccessStrategyAfterSell["KeepAccessTillCurrentAccessPeriod"] = "keepTillCurrentAccessPeriod";
})(AccessStrategyAfterSell || (AccessStrategyAfterSell = {}));
export var Chain;
(function (Chain) {
    Chain["Ethereum"] = "eth";
    Chain["Polygon"] = "polygon";
    Chain["Goerli"] = "goerli";
})(Chain || (Chain = {}));
