import { createAsyncThunk } from '@reduxjs/toolkit';
import { INTEGRATION_REDUCER_NAME } from '../types';
export const removeIntegrationWithEmailClient = createAsyncThunk(`${INTEGRATION_REDUCER_NAME}/removeIntegrationWithEmailClient`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-removeIntegrationWithEmailClient')(payload);
        analytics.track('remove_integration_with_email_client', {
            userId: user.uid,
            email: user.email,
            client: payload.client,
        });
        onSuccess?.();
    }
    catch (e) {
        const error = e;
        onFailure?.(error.code);
        return rejectWithValue(e);
    }
});
