import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { FIRESTORE_DOCUMENT } from '../../../../firestore/documentNames';
import { ArchiveStrategyAccess, } from '../../../../models/documents/ProductAutomationDocument';
import { ArchivedEmailStatus, ArchivedEmailVisibility, } from '../../../../models/documents/ArchivedEmailDocument';
export const updateProductAutomationArchiveSettings = createAsyncThunk(`${PRODUCT_AUTOMATION_REDUCER_NAME}/updateProductAutomationArchiveSettings`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth }, getState }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('invalid-user');
        }
        const { data } = getState().productAutomation;
        if (!data) {
            throw new Error('product automation data not exists');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_CONFIG)
            .doc(FIRESTORE_DOCUMENT.PRODUCT_AUTOMATION);
        await ref.update({
            archiveSettings: payload.settings
                ? payload.settings
                : {
                    accessStrategy: ArchiveStrategyAccess.Unlimited,
                    automation: {
                        onNewEmail: {
                            privateArchiveStatus: ArchivedEmailStatus.Public,
                            publicArchiveStatus: ArchivedEmailVisibility.Hidden,
                        },
                    },
                },
            updatedAt: new Date(),
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
