import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_RESOURCES_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const removeProductResource = createAsyncThunk(`${PRODUCT_RESOURCES_REDUCER_NAME}/removeProductResource`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('invalid-user');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_RESOURCES)
            .doc(payload.resourceId);
        await ref.delete();
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
