import { createSlice } from '@reduxjs/toolkit';
import { COMMUNITY_MEMBERS_REDUCER_NAME } from './types';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    adding: false,
    error: null,
    data: [],
    requestStatus: null,
    searchTerm: null,
};
// TODO: add pagination support
const communityMembersSlice = createSlice({
    name: COMMUNITY_MEMBERS_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToCommunityMembersStarted(state) {
            state.requestStatus = RequestStatus.SUBSCRIBING;
            state.error = null;
        },
        subscribeToCommunityMembersSucceeded(state, { payload }) {
            state.requestStatus = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToCommunityMembersFailed(state, { payload }) {
            state.requestStatus = RequestStatus.FAILED;
            state.error = payload;
        },
        unsubscribeFromCommunityMembers(state) {
            state.data = [];
            state.error = null;
        },
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
});
export const { subscribeToCommunityMembersStarted, subscribeToCommunityMembersFailed, subscribeToCommunityMembersSucceeded, unsubscribeFromCommunityMembers, clearSearchMode, setSearchTerm, } = communityMembersSlice.actions;
export default communityMembersSlice.reducer;
