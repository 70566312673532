import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const importClientsList = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/importClientsList`, async (payload, { rejectWithValue, extra: { auth, functions, analytics } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        await functions.httpsCallable('products-scheduleImportClientsToProducts')({
            clients: payload.list,
            productId: payload.productId,
        });
        analytics.track('import_clients_to_products', {
            productId: payload.productId,
            count: payload.list.length,
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
