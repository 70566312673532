import { PRODUCT_USER_SETTINGS_CONFIG_REDUCER_NAME, } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    status: null,
    data: null,
    error: null,
};
const productUserSettingsConfigSlice = createSlice({
    name: PRODUCT_USER_SETTINGS_CONFIG_REDUCER_NAME,
    initialState,
    reducers: {
        subscribeToProductUserSettingsConfigStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
            state.error = null;
            state.data = null;
        },
        subscribeToProductUserSettingsConfigSuccess(state, { payload }) {
            state.status = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToProductUserSettingsConfigFailed(state, { payload }) {
            state.status = RequestStatus.FAILED;
            state.error = payload.message;
            state.data = null;
        },
        unsubscribeFromProductUserSettingsConfig(state) {
            state.status = null;
            state.data = null;
            state.error = null;
        },
    },
});
export const { subscribeToProductUserSettingsConfigFailed, subscribeToProductUserSettingsConfigStarted, subscribeToProductUserSettingsConfigSuccess, unsubscribeFromProductUserSettingsConfig, } = productUserSettingsConfigSlice.actions;
export default productUserSettingsConfigSlice.reducer;
