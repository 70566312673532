export var MailingrBotPosition;
(function (MailingrBotPosition) {
    MailingrBotPosition["NotAvailable"] = "notAvailable";
    MailingrBotPosition["TooLow"] = "tooLow";
    MailingrBotPosition["Valid"] = "valid";
})(MailingrBotPosition || (MailingrBotPosition = {}));
export const BotName = 'MailingR Integration';
export const checkMailingrBotPositionOnRolesList = (roles, roleId) => {
    const mailingrBotRole = roles?.find((role) => role.tags?.bot_id && role.name === BotName);
    if (!roles || !roles.length || !mailingrBotRole) {
        return MailingrBotPosition.NotAvailable;
    }
    const position = mailingrBotRole.position;
    if (roleId) {
        const role = roles.find((role) => role.id === roleId);
        return role && role.position < position
            ? MailingrBotPosition.Valid
            : MailingrBotPosition.TooLow;
    }
    const availableRoles = roles
        .filter((role) => role.name !== '@everyone' && !role.tags?.bot_id)
        .map((role) => role.position);
    const highestPosition = Math.max(...availableRoles);
    return position > highestPosition ? MailingrBotPosition.Valid : MailingrBotPosition.TooLow;
};
