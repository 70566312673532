import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_AUTOMATION_REDUCER_NAME } from '../types';
export const refreshDiscordIntegration = createAsyncThunk(`${PRODUCT_AUTOMATION_REDUCER_NAME}/refreshDiscordIntegration`, async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('integrations-refreshIntegrationWithDiscord')(payload);
        analytics.track('refresh_connected_discord_server', {
            userId: user.uid,
            email: user.email,
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
