import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEWSLETTERS_REDUCER_NAME } from '../../newsletters/types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateInvoiceRequest = createAsyncThunk(`${NEWSLETTERS_REDUCER_NAME}/updateInvoiceRequest`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(payload.authorId)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(payload.productId)
            .collection(COLLECTION.PRODUCT_CLIENTS)
            .doc(user.email);
        await ref.update({
            invoiceRequested: payload.invoiceRequest,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
