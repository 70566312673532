import { createSlice } from '@reduxjs/toolkit';
import { ADMIN_REDUCER_NAME } from './types';
import { fetchProducts, fetchProductsOwners, searchProductOrOwner } from './actions';
import { RequestStatus } from '../../../enums/requestStatus';
const initialState = {
    owners: [],
    productsByOwnerId: {},
    productsByOwnerIdStatus: {},
    ownersListStatus: null,
    searchTerm: null,
    searchStatus: null,
};
const reducerSlice = createSlice({
    initialState,
    name: ADMIN_REDUCER_NAME,
    reducers: {
        clearSearchMode(state) {
            state.searchTerm = '';
        },
        setSearchTerm(state, { payload }) {
            state.searchTerm = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductsOwners.pending, (state) => {
            state.ownersListStatus = RequestStatus.FETCHING;
        });
        builder.addCase(fetchProductsOwners.fulfilled, (state, action) => {
            state.owners = action.payload;
            state.ownersListStatus = RequestStatus.SUCCESS;
        });
        builder.addCase(fetchProductsOwners.rejected, (state) => {
            state.ownersListStatus = RequestStatus.FAILED;
        });
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.productsByOwnerIdStatus[action.meta.arg] = RequestStatus.FETCHING;
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            const ownerId = action.meta.arg;
            state.productsByOwnerIdStatus[ownerId] = RequestStatus.SUCCESS;
            state.productsByOwnerId[ownerId] = action.payload;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.productsByOwnerIdStatus[action.meta.arg] = RequestStatus.FAILED;
        });
        builder.addCase(searchProductOrOwner.pending, (state, action) => {
            state.searchTerm = action.meta.arg.searchTerm;
            state.searchStatus = RequestStatus.FETCHING;
        });
        builder.addCase(searchProductOrOwner.fulfilled, (state, action) => {
            state.owners = action.payload;
            state.searchStatus = RequestStatus.SUCCESS;
        });
        builder.addCase(searchProductOrOwner.rejected, (state) => {
            state.searchStatus = RequestStatus.FAILED;
        });
    },
});
export const { clearSearchMode } = reducerSlice.actions;
export default reducerSlice.reducer;
