import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_REDUCER } from '../reducer';
export const upsertSalesPageSettings = createAsyncThunk(`${USER_REDUCER}/upsertSalesPageSettings`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged');
        }
        await functions.httpsCallable('users-upsertSalesPageSettings')(payload);
        analytics.track('update_sales_page_settings', {
            userId: user.uid,
            email: user.email,
            slug: payload.slug ?? '',
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.(e.code);
        return rejectWithValue(e);
    }
});
