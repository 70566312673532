import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { firestoreDateMapper } from '../../../../helpers/firestoreDateMapper';
export const fetchAllProductClientImports = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchAllProductClientImports`, async ({ productId, importId, type }, { rejectWithValue, extra: { auth, db } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('user-is-not-logged');
        }
        const ref = db
            .collection(COLLECTION.USERS)
            .doc(user.uid)
            .collection(COLLECTION.PRODUCTS_LIST)
            .doc(productId)
            .collection(COLLECTION.PRODUCT_IMPORTS)
            .where('importId', '==', importId)
            .where('status', 'in', type === 'all' ? ['imported', 'failed'] : [type])
            .orderBy('createdAt', 'desc');
        const res = await ref.get();
        const list = res.docs.map((doc) => firestoreDateMapper(doc));
        return {
            list,
        };
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
