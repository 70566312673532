import { getUserDetailsFailed, getUserDetailsStarted, getUserDetailsSuccess } from '../reducer';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { setUserPresence } from './setUserPresence';
import { UserActivityStatus } from '../../../../enums/userActivityStatus';
import { logOutUser } from './logOutUser';
export const getUserDetails = (uid) => async (dispatch, getState, { db, database, firestoreSubscriptions, analytics, auth }) => {
    dispatch(getUserDetailsStarted());
    try {
        const ref = db.collection(COLLECTION.USERS).doc(uid);
        if (firestoreSubscriptions.userDetailsListener) {
            firestoreSubscriptions.userDetailsListener();
        }
        const idTokenResult = await auth().currentUser?.getIdTokenResult();
        firestoreSubscriptions.userDetailsListener = ref.onSnapshot((snapshot) => {
            const data = snapshot.data();
            dispatch(getUserDetailsSuccess(data
                ? {
                    ...data,
                    createdAt: data.createdAt?.toDate(),
                    updatedAt: data.updatedAt?.toDate(),
                    systemRole: idTokenResult?.claims?.systemRole || null,
                }
                : undefined));
            dispatch(setUserPresence(database ? undefined : UserActivityStatus.Online));
            if (data) {
                analytics.updateUserProperties(uid, {
                    roles: data.allowedRoles,
                    email: data?.email,
                    name: data.firstName ? `${data.firstName} ${data.lastName ?? ''}` : undefined,
                });
            }
        }, () => {
            dispatch(getUserDetailsFailed());
            dispatch(logOutUser());
        });
    }
    catch (e) {
        dispatch(getUserDetailsFailed());
    }
};
