import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../enums/requestStatus';
import { PRODUCT_CONTENT_REDUCER_NAME } from './types';
import { updateContentModulesOrder } from './actions/updateContentModulesOrder';
import { addContentModule } from './actions/addContentModule';
import isEmpty from 'lodash.isempty';
const initialState = {
    data: null,
    status: null,
    selectedLesson: null,
    lessonStatus: null,
    openedModules: {},
};
const reducerSlice = createSlice({
    initialState,
    name: PRODUCT_CONTENT_REDUCER_NAME,
    reducers: {
        subscribeToProductContentStarted(state) {
            state.status = RequestStatus.SUBSCRIBING;
        },
        subscribeToProductContentSuccess(state, { payload }) {
            if (state.status === RequestStatus.SUBSCRIBING &&
                isEmpty(state.openedModules) &&
                payload?.modules.length) {
                state.openedModules[payload.modules[0].id] = true;
            }
            state.status = RequestStatus.SUBSCRIBED;
            state.data = payload;
        },
        subscribeToProductContentFailed(state) {
            state.status = RequestStatus.FAILED;
        },
        unsubscribeFromProductContent(state) {
            state.data = null;
            state.status = null;
        },
        subscribeToProductContentLessonStarted(state) {
            state.lessonStatus = RequestStatus.SUBSCRIBING;
        },
        subscribeToProductContentLessonSuccess(state, { payload }) {
            state.lessonStatus = RequestStatus.SUBSCRIBED;
            state.selectedLesson = payload;
        },
        subscribeToProductContentLessonFailed(state) {
            state.lessonStatus = RequestStatus.FAILED;
        },
        unsubscribeFromProductContentLesson(state) {
            state.selectedLesson = null;
            state.lessonStatus = null;
        },
        changeOpenedModules(state, action) {
            if (state.openedModules[action.payload]) {
                delete state.openedModules[action.payload];
            }
            else {
                state.openedModules[action.payload] = true;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateContentModulesOrder.pending, (state, { meta }) => {
            if (state.data?.productId === meta.arg.productId) {
                state.data.modules = meta.arg.modules;
            }
        });
        builder.addCase(addContentModule.fulfilled, (state, { payload }) => {
            state.openedModules[payload] = true;
        });
    },
});
export const { subscribeToProductContentStarted, subscribeToProductContentSuccess, subscribeToProductContentFailed, unsubscribeFromProductContent, unsubscribeFromProductContentLesson, subscribeToProductContentLessonStarted, subscribeToProductContentLessonSuccess, subscribeToProductContentLessonFailed, changeOpenedModules, } = reducerSlice.actions;
export default reducerSlice.reducer;
