import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { getDocumentConverter } from '../../../../helpers/firestoreDateMapper';
export const fetchProductCourseProgress = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/fetchProductCourseProgress`, async ({ productId }, { extra: { db, firestoreSubscriptions }, getState }) => {
    const { productCourseProgressSize } = getState().product;
    const ref = db
        .collection(COLLECTION.PRODUCT_COURSE_PROGRESS)
        .where('productId', '==', productId)
        .orderBy('numberOfSessions', 'desc')
        .limit(productCourseProgressSize)
        .withConverter(getDocumentConverter());
    const snapshot = await ref.get();
    const list = snapshot.docs.map((doc) => doc.data());
    if (!snapshot.empty) {
        firestoreSubscriptions.lastProductCourseProgressCursor =
            snapshot.docs[snapshot.docs.length - 1];
    }
    return {
        list,
        isLastPage: list.length < productCourseProgressSize,
    };
});
