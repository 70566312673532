export var RequestStatus;
(function (RequestStatus) {
    RequestStatus["IDLE"] = "IDLE";
    RequestStatus["FETCHING"] = "FETCHING";
    RequestStatus["SUCCESS"] = "SUCCESS";
    RequestStatus["FAILED"] = "FAILED";
    RequestStatus["SUBSCRIBING"] = "SUBSCRIBING";
    RequestStatus["SUBSCRIBED"] = "SUBSCRIBED";
    RequestStatus["UPDATING"] = "UPDATING";
})(RequestStatus || (RequestStatus = {}));
