import { logOutFailed, logOutStarted, logOutSuccess } from '../reducer';
import { unsubscribeFromUserDetails } from './unsubscribeFromUserDetails';
import { setUserPresence } from './setUserPresence';
import { UserActivityStatus } from '../../../../enums/userActivityStatus';
import { unsubscribeFromUserReferrals } from '../../userReferrals/actions';
export const logOutUser = (callback) => async (dispatch, getState, { auth }) => {
    dispatch(logOutStarted());
    try {
        await dispatch(setUserPresence(UserActivityStatus.Offline));
        await auth().signOut();
        dispatch(unsubscribeFromUserDetails());
        dispatch(unsubscribeFromUserReferrals());
        dispatch(logOutSuccess());
        callback?.();
    }
    catch (e) {
        dispatch(logOutFailed(e.code));
    }
};
