import { createAsyncThunk } from '@reduxjs/toolkit';
import { PRODUCT_REDUCER_NAME } from '../types';
export const archiveProductClient = createAsyncThunk(`${PRODUCT_REDUCER_NAME}/archiveProductClient`, async (payload, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        await functions.httpsCallable('products-archiveProductClient')(payload);
        analytics.track('archive_product_client', {
            productId: payload.productId,
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
    }
    catch (e) {
        return rejectWithValue(e);
    }
});
