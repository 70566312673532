import { AliasToken } from 'antd/es/theme/interface';

export const appTheme: Partial<AliasToken> = {
  colorPrimary: '#2f90d6',
  colorLink: '#2f90d6',
  colorSuccess: '#1bc5bd',
  colorWarning: '#ffa800',
  colorError: '#f64e60',
  colorInfo: '#2c4790',
  colorTextDisabled: '#b5b5c3',
  colorWhite: '#fff',
  colorInfoBg: '#e6f7ff',
  colorInfoBorder: '#91d5ff',
  borderRadius: 2,
  fontFamily: 'Poppins',
};
