import { createAsyncThunk } from '@reduxjs/toolkit';
import { DISCOUNT_REDUCER_NAME } from '../types';
export const addDiscount = createAsyncThunk(`${DISCOUNT_REDUCER_NAME}/addDiscount`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { functions, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        await functions.httpsCallable('discounts-createDiscount')(payload);
        analytics.track('add-discount', {
            discountId: 'new',
            userId: user?.uid ?? '',
            email: user?.email ?? '',
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
